import React from 'react';
import {
  Stack,
  HStack,
  Link,
  Button,
  Heading,
  Spacer,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  Img,
  Text,
  Icon,
  Tooltip,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Drawer from './Drawer';
import technology from '../../assets/images/technology.png';
// import advisory from '../../assets/images/pot.jpeg'
import advisory from '../../assets/images/pot.jpg';
import specificchange from '../../assets/images/st.jpeg';
import digital from '../../assets/images/aba.jpeg';
import logo from '../../assets/images/newslogo.svg';
import { BsChevronDown } from 'react-icons/bs';
import TextTrimmer from './TextTrimmer';
const HrResourcesDropdown = () => {
  const nav = useNavigate();

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          px={0}
          fontWeight={'500'}
          _active={{}}
          border="none"
          _hover={{ color: '#FF6217' }}
          rightIcon={<BsChevronDown />}
          variant="outline"
          color="white"
          w="fit-content"
        >
          Resources
        </Button>
      </PopoverTrigger>
      <PopoverContent
        px={4}
        py={4}
        outlineColor={'transparent'}
        _focusVisible={{}}
        bg="white"
        width="full"
      >
        <PopoverArrow />
        <PopoverBody>
          <HStack alignItems={'start'} spacing={12} w="100%">
            <Stack w="250px">
              <Text
                cursor="pointer"
                onClick={() => nav('/aba-therapy-resources')}
                fontSize={'lg'}
                fontWeight={'600'}
                color="#17496D"
              >
                ABA Therapy Resources
              </Text>
              <Text fontSize="sm" color="#343434" fontWeight={'500'}>
                These resources should give you a solid foundation in
                understanding ABA therapy and its applications.
              </Text>
            </Stack>
            <Stack w="250px">
              <Text
                cursor="pointer"
                onClick={() => nav('/speech-therapy-resources')}
                fontSize={'lg'}
                fontWeight={'600'}
                color="#17496D"
              >
                Speech Therapy Resources
              </Text>
              <Text fontSize="sm" color="#343434" fontWeight={'500'}>
                These resources should provide you with a comprehensive
                understanding of speech therapy and its applications across
                different populations among settings.
              </Text>
            </Stack>
            <Stack w="250px">
              <Text
                cursor="pointer"
                onClick={() => nav('/occupational-therapy-resources')}
                fontSize={'lg'}
                fontWeight={'600'}
                color="#17496D"
              >
                Occupational Therapy Resources
              </Text>
              <Text fontSize="sm" color="#343434" fontWeight={'500'}>
              These resources can provide valuable information and insights into occupational therapy practice. 
              </Text>
            </Stack>
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
const HrServicesDropdown = () => {
  const nav = useNavigate();

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          fontWeight={'500'}
          _active={{}}
          _hover={{ color: '#FF6217' }}
          border="none"
          rightIcon={<BsChevronDown />}
          variant="outline"
          color="white"
          w="fit-content"
          px={0}
        >
          Our Programs
        </Button>
      </PopoverTrigger>
      <PopoverContent
        px={4}
        py={4}
        outlineColor={'transparent'}
        _focusVisible={{}}
        bg="white"
        width="full"
      >
        <PopoverArrow />
        <PopoverBody>
          <HStack alignItems={'start'} spacing={20} w="100%">
            <Stack w="270px">
              <Img
                borderRadius={'3px'}
                h="20vh"
                objectFit={'cover'}
                src={digital}
              />
              <Text pt={3} fontSize={'lg'} fontWeight={'600'} color="#17496D">
                Applied Behaviour Analysis (ABA) Therapy
              </Text>
              <TextTrimmer
                text="Applied Behavior Analysis (ABA) therapy is a therapeutic approach widely used to address various behavioral challenges, particularly in children with autism spectrum disorder (ASD) and developmental disabilities. ABA is based on the principles of behaviorism and focuses on understanding how behavior is influenced by the environment."
                maxWords={25}
              />

              <Button
                size="md"
                color="white"
                _hover={{}}
                fontWeight={'400'}
                bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
                boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
                borderRadius={'5px'}
                onClick={() => nav('/aba-therapy')}
              >
                Learn More
              </Button>
            </Stack>
            <Stack w="270px">
              <Img
                borderRadius={'3px'}
                h="20vh"
                objectFit={'cover'}
                src={specificchange}
              />
              <Text
                pt={3}
                pr={12}
                fontSize={'lg'}
                fontWeight={'600'}
                color="#17496D"
              >
                Speech Therapy
              </Text>
              <TextTrimmer
                text="Speech-language therapy, also known as speech therapy, is a specialized form of therapy that focuses on assessing, diagnosing, and treating communication and speech disorders. Speech-language therapists, or speech-language pathologists (SLPs) "
                maxWords={30}
              />

              <Button
                size="md"
                color="white"
                _hover={{}}
                fontWeight={'400'}
                bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
                boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
                borderRadius={'5px'}
                onClick={() => nav('/speech-therapy')}
              >
                Learn More
              </Button>
            </Stack>
            <Stack spacing={4} w="270px">
              <Stack>
                <Img
                  borderRadius={'3px'}
                  h="20vh"
                  objectFit={'cover'}
                  src={advisory}
                />
                <Text
                  pt={3}
                  pr={16}
                  fontSize={'lg'}
                  fontWeight={'600'}
                  color="#17496D"
                >
                  Occupational Therapy
                </Text>
                <TextTrimmer
                  text="Occupational therapy (OT) is a healthcare profession that focuses on helping children develop, regain, or maintain the skills needed for daily living and meaningful activities, or occupations. Occupational therapists work with learners of all ages who may be facing physical, developmental, emotional, or cognitive challenges that affect their ability to engage in activities that are important to them."
                  maxWords={30}
                />

                <Button
                  size="md"
                  color="white"
                  _hover={{}}
                  bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
                  boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
                  borderRadius={'5px'}
                  onClick={() => nav('/occupational-Therapy')}
                >
                  Learn More
                </Button>
              </Stack>
            </Stack>
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
const Header = () => {
  const nav = useNavigate();
  return (
    <Stack
      // position={'fixed'}
      width={'100%'}
      bgGradient="linear(15.46deg, #17496D 26.3%, #418DB8 86.4%)"
      // alignItems={{ md: 'none', lg: 'center' }}
      px={{ base: 3, md: 6, lg: 12, xl: 20 }}
      py={2}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Img w={'50px'} cursor="pointer" onClick={() => nav('/')} src={logo} />

        <Drawer />
        <Stack
          display={{ base: 'none', md: 'none', xl: 'inherit' }}
          // px={10}
          py={2}
        >
          <HStack gap={{ base: '20px', xl: '30px' }}>
            <HrServicesDropdown />
            <Link
              onClick={() => nav('/our-team')}
              fontWeight={'500'}
              fontSize={{ base: '13px', xl: '15px' }}
              color={'white'}
              _hover={{
                textDecoration: 'none',
                color: '#FF6217',
                // fontWeight: 'bold',
              }}
            >
              Our Team
            </Link>
            <HrResourcesDropdown />

            <Link
              onClick={() => nav('/about-us')}
              fontWeight={'500'}
              fontSize={{ base: '13px', xl: '15px' }}
              color={'white'}
              _hover={{
                textDecoration: 'none',
                color: '#FF6217',
                // fontWeight: 'bold',
              }}
            >
              About Us
            </Link>
            <Link
              onClick={() => nav('/contact-us')}
              fontWeight={'500'}
              fontSize={{ base: '13px', xl: '15px' }}
              color={'white'}
              _hover={{
                textDecoration: 'none',
                color: '#FF6217',
                // fontWeight: 'bold',
              }}
            >
              Contact Us
            </Link>
            <Link
              onClick={() => nav('/subscribe')}
              fontWeight={'500'}
              fontSize={{ base: '13px', xl: '15px' }}
              color={'white'}
              _hover={{
                textDecoration: 'none',
                color: '#FF6217',
                // fontWeight: 'bold',
              }}
            >
              Subscribe
            </Link>
            <Tooltip
              p={3}
              hasArrow
              label="Our Attract to Alumni Legal (A2AL)AI application swiftly answers your questions, providing efficient legal insights."
            >
              <Button
                borderRadius={'5px'}
                color={'#FF6217'}
                bgColor={'white'}
                fontWeight={'600'}
                boxShadow={'0px 4px 32px 0px rgba(0, 0, 0, 0.25)'}
                fontSize={{ base: '13px', xl: '15px' }}
                px={10}
                _hover={{}}
                size={'md'}
                // onClick={() => window.open('https://hr.snsa2a.ai/', '_blank')}
              >
                Lorem
              </Button>
            </Tooltip>
          </HStack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Header;
