import React, { useEffect, useState } from 'react';
import Layout from '../../layout/Layout';
import { useLocation } from 'react-router-dom';
import PDFViewer from '../../fileviewers/PDFViewer';

const DocumentDetailPage = () => {
  const location = useLocation();
  console.log('🚀 ~ file: HrNews.js:24 ~ HrNews ~ location:', location?.state);

  return (
    <Layout>
      <PDFViewer fileUrl={location?.state} />
    </Layout>
  );
};

export default DocumentDetailPage;
