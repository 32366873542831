import React, { useState } from 'react';
import { Flex, Text, Input, Button, Link, Stack, Img, useToast } from '@chakra-ui/react';
import logo from '../../assets/images/logowhite.png';
import { useNavigate } from 'react-router-dom';
import { forgotPasswordService } from '../../services/Service';
const ForgotPasswordPage = () => {
  const toast = useToast()
  const nav = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setloading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [submitClicked, setSubmitClicked] = useState(false);

  const handleEmailChange = event => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };

  const validateEmail = () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!emailRegex.test(email)) {
      setEmailError('Invalid email address.');
    } else {
      setEmailError('');
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setSubmitClicked(true);
    validateEmail();

    if (!emailError) {

      // Handle successful form submission
      console.log('Form submitted successfully.');
      setloading(true)
      // Handle successful form submission
      console.log('Form submitted successfully.');
      const res = await forgotPasswordService(email)
      console.log("🚀 ~ file: Login.js:78 ~ handleSubmit ~ res:", res)
      if (res.success) {
        setloading(false)
        toast({
          title: res.message,
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        })
        nav('/reset-password', { state: { email: email } })
        setEmail()
      }
      else {
        setloading(false)
        toast({
          title: res.message,
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        })
      }
    } else {
      console.log('Form has errors. Please correct them.');
    }
  };

  return (
    <Stack gap={0} overflow={'hidden'} maxWidth={'100%'} alignItems={'center'}>
      <Stack
        w="100%"
        justifyContent={'center'}
        direction="row"
        bg={
          'var(--gradient, linear-gradient(15deg, #17496D 0%, #418DB8 92.71%))'
        }
        height={'7vh'}
        alignItems={'center'}
        boxShadow={'0px 8px 30px 0px rgba(13, 24, 33, 0.08)'}
      >
        <Img w={6} src={logo} />
        <Text fontSize={'lg'} color="white">
          SnSHRhub
        </Text>
      </Stack>
      <Stack
        bg="#F8FBFF"
        h="93vh"
        direction="row"
        justifyContent={'center'}
        alignItems={'center'}
        width="100vw"
      >
        <Flex
        h='full'
          px={{base:6,sm:8,md:12,lg:24}}
          height={'100%'}
          width={{base:'100%',md:'50%'}}
          textAlign={'left'}
          align="center"
        >
          <Stack width="100%" spacing="6">
            <Stack textAlign={{base:'start',md:'center'}}>
              <Text fontWeight={'600'} fontSize={'xl'} color={'#0D1821'}>
                Forgot Password?
              </Text>
              <Text fontSize={{base:'sm',md:'md'}}>
                Enter the Email address associated with your account and we’ll
                send you a link to reset your password
              </Text>
            </Stack>
            <form style={{ width: '100%' }} onSubmit={handleSubmit}>
              <Stack spacing={6}>
                <Stack spacing={2}>
                  <Text fontWeight={'600'} fontSize={'sm'}>
                    Account Email Address
                  </Text>
                  <Input
                    borderRadius={'full'}
                    bg="white"
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                  />

                  {emailError && (
                    <Text color="red" fontSize={'sm'}>
                      {emailError}
                    </Text>
                  )}
                </Stack>
                <Button
                  isLoading={loading}
                  w="100%"
                  color={'white'}
                  borderRadius={'full'}
                  bg="var(--gradient, linear-gradient(15deg, #17496D 0%, #418DB8 92.71%))"
                  _hover={{}}
                  type="submit"
                  isDisabled={emailError || !email}
                  fontWeight={'500'}
                >
                  Send Verification Code
                </Button>
              </Stack>
            </form>
            <Link onClick={() => nav('/')} textAlign={'center'} color="#3C98FD">
              Back To Login
            </Link>
          </Stack>
        </Flex>
      </Stack>
    </Stack>
  );
};

export default ForgotPasswordPage;
