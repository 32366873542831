import { Stack } from '@chakra-ui/react';
import React from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';

const Layout = ({ children }) => {
  return (
    <Stack minH={'100vh'} justifyContent={'space-between'}>
      <Header />
      <Stack >{children}</Stack>
      <Footer />
    </Stack>
  );
};

export default Layout;
