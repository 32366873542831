import React from 'react';
import Layout from '../../layout/Layout';
import { Button, Heading, Image, Img, Stack, Text } from '@chakra-ui/react';
import st from '../../../assets/images/aba.jpeg';
import contactus from '../../../assets/images/contactus.svg';
import { useNavigate } from 'react-router-dom';

const HrDigitalTranformation = () => {
  const nav = useNavigate();
  return (
    <Layout>
      <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={6}>
        <Heading
          color={'#17496D'}
          fontSize={{ base: '1rem', md: '1.8rem', lg: '2rem' }}
          fontWeight={'700'}
          className="heading"
          mb={8}
        >
          Our Programs
        </Heading>
        <Stack
          gap={{ base: 12, md: 10, lg: 12, xl: '7vw' }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Img
            w={{ base: '100%', md: '50%' }}
            h={{ base: '40vh', md: '50vh', lg: '60vh' }}
            src={st}
            borderRadius={'5px'}
          />
          <Stack gap={0}>
            {/* <Text fontWeight={'600'} fontSize={'15px'} color="#FF6217">
              HR Digital Transformation Services
            </Text> */}
            <Heading
              color={'#17496D'}
              fontSize={{ base: '1rem', md: '1.8rem', lg: '1.8rem' }}
              fontWeight={'700'}
              className="heading"
              mt={2}
              mb={4}
            >
              Applied Behaviour Analysis (ABA) Therapy
            </Heading>
            <div>
              <div>
                Applied Behavior Analysis (ABA) therapy is a therapeutic
                approach widely used to address various behavioral challenges,
                particularly in children with autism spectrum disorder (ASD) and
                developmental disabilities. ABA is based on the principles of
                behaviorism and focuses on understanding how behavior is
                influenced by the environment.
              </div>
              <br />
              <div>
                ABA therapy can be implemented in various settings, including
                homes, schools, and clinical settings. The specific skills
                addressed in ABA therapy depend on the unique needs and goals of
                the learner receiving treatment. Common areas of focus include
                communication deficits, social skills, self-care (such as
                showering, and toileting), daily living skills, play skills,
                group instruction, classroom routines, motor skills, and
                academic skills. The individualized and data-driven nature of
                ABA makes it a flexible and effective approach for addressing
                the unique needs of children with autism.
              </div>
              <br />
              <div>Key components of ABA therapy include:</div>
              <br />
              <div>
                <strong style={{ color: '#17496D' }}>
                  1- Behaviour Assessment:{' '}
                </strong>{' '}
                ABA begins with a thorough assessment of the individual's
                behavior, skills, and needs. This assessment helps in
                identifying target behaviors that need modification or
                improvement.
              </div>
              <br />
              <div>
                <strong style={{ color: '#17496D' }}>
                  2- Functional Behaviour Assessment (FBA):{' '}
                </strong>{' '}
                A more in-depth analysis is conducted through an FBA to
                understand the function or purpose of a specific behavior. This
                involves identifying antecedents (what happens before the
                behavior), the behavior itself, and the consequences (what
                happens after the behavior).
              </div>
              <br />
              <div>
                <strong style={{ color: '#17496D' }}>
                  3- Behaviour Intervention Plan (BIP):{' '}
                </strong>{' '}
                Based on the assessment, a tailored Behavior Intervention Plan
                is developed. This plan outlines specific strategies and
                interventions to address targeted behaviors, promote positive
                behaviors, and teach new skills.
              </div>
              <br />
              <div>
                <strong style={{ color: '#17496D' }}>4- Reinforcement: </strong>{' '}
                ABA relies heavily on reinforcement, which involves providing
                consequences (positive or negative) to strengthen or weaken a
                behavior. Positive reinforcement, such as rewards, is commonly
                used to encourage desired behaviors.
              </div>
              <br />
              <div>
                <strong style={{ color: '#17496D' }}>
                  5- Prompting and Prompting Fading{' '}
                </strong>{' '}
                ABA utilizes prompts to assist learners in performing desired
                behaviors. Over time, prompts are systematically faded to
                promote independent functioning.
              </div>
              <br />

              <div>
                <strong style={{ color: '#17496D' }}>
                  6- Data Collection and Analysis:{' '}
                </strong>{' '}
                ABA places a strong emphasis on data collection to objectively
                measure behavior change. This data is regularly analyzed to
                assess progress and make necessary adjustments to the
                intervention plan.
              </div>
              <br />

              <div>
                <strong style={{ color: '#17496D' }}>
                  7- Generalization :{' '}
                </strong>{' '}
                ABA aims to promote the generalization of learned skills across
                various settings, people, and situations. This helps each
                learner to apply their learned skills in different contexts.
              </div>
              <br />

              <div>
                It's important to note that ABA is highly individualized, and
                the specific skills addressed will depend on the assessment of
                the individual's strengths and challenges. A qualified behavior
                analyst (BCBA) will work with the child and their caregivers to
                develop a personalized intervention plan that targets specific
                goals and objectives tailored to the learners needs and
                abilities. ABA Therapists, Teachers or other paraprofessionals
                will implement the plan developed by the BCBA.
              </div>
            </div>
            {/* <Text
              color={'#343434'}
              fontSize={{ base: '0.75rem', md: '0.85rem', lg: '0.95rem' }}
              fontWeight={'500'}
              marginBottom={'0'}
              whiteSpace="pre-line"
              lineHeight={{ base: '23px', lg: '27px' }}
            >
              {text}
            </Text> */}
            <Button
              mt={3}
              onClick={() => nav('/contact-us')}
              size={{ base: 'sm', md: 'lg' }}
              leftIcon={<Image src={contactus} />}
              color="white"
              _hover={{}}
              bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
              boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
              borderRadius={'5px'}
              fontWeight={'400'}
              w="fit-content"
            >
              Work with us
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default HrDigitalTranformation;
