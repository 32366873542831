import React from 'react';
import {
  Button,
  HStack,
  Heading,
  Img,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import arrow from '../../assets/images/Vector.svg';
import augmentation from '../../assets/images/augmentation.jpg';
import abatherapy from '../../assets/images/aba.jpeg';
import pottheraphy from '../../assets/images/pot.jpeg';
import speechtherapy from '../../assets/images/st.jpeg';
import { useNavigate } from 'react-router-dom';
const HRServices = () => {
  const nav = useNavigate();
  return (
    <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={6}>
      <Heading
        color={'#17496D'}
        fontSize={{ base: '1rem', md: '1.8rem', lg: '2rem' }}
        fontWeight={'700'}
        className="heading"
        mb={4}
      >
        Our Programs
      </Heading>
      <Spacer />

      <Stack
        mb={'8vh'}
        direction={{ base: 'column', md: 'row' }}
        gap={{ base: 12, md: 10, lg: 12, xl: '7vw' }}
      >
          <Stack
          
          w={{ base: '100%', md: '50%' }}
          spacing={6}
        >
          <Img
            h={{ base: '50vh', lg: '58vh' }}
            borderRadius={'5px'}
            // objectFit={'cover'}
            src={abatherapy}
          />
          <Heading
            color={'#000000'}
            fontSize={{
              base: '1rem',
              md: '1.3rem',
              lg: '1.3rem',
              xl: '1.5rem',
            }}
            fontWeight={'bold'}
            className="heading"
            lineHeight={'35px'}
            w={{ base: '100%', lg: '70%' }}
          >
            Applied Behaviour Analysis (ABA) Therapy
          </Heading>
          <Text
            color={'#343434'}
            fontSize={{ base: '0.75rem', md: '1.1rem', lg: '1rem' }}
            fontWeight={'500'}
          >
            Applied Behavior Analysis (ABA) therapy is a therapeutic approach
            widely used to address various behavioral challenges, particularly
            in children with autism spectrum disorder (ASD) and developmental
            disabilities. ABA is based on the principles of behavioris m and
            focuses on understanding how behavior is influenced by the
            environment.
          </Text>
          <Button
            size={{ base: 'sm', md: 'md' }}
            color="white"
            _hover={{}}
            bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
            boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
            borderRadius={'5px'}
            fontWeight={'400'}
            onClick={() => nav('/aba-Therapy')}
          >
            Learn More
          </Button>
        </Stack>
        <Stack w={{ base: '100%', md: '50%' }} spacing={6}>
          <Img
            h={{ base: '50vh', lg: '58vh' }}
            borderRadius={'5px'}
            // objectFit={'cover'}
            src={speechtherapy}
          />
          <Heading
            color={'#000000'}
            fontSize={{
              base: '1rem',
              md: '1.3rem',
              lg: '1.3rem',
              xl: '1.5rem',
            }}
            fontWeight={'bold'}
            className="heading"
            lineHeight={'35px'}
            // w={{ base: '100%', xl: '70%' }}
          >
            Speech Therapy
          </Heading>
          <Text
            color={'#343434'}
            fontSize={{ base: '0.75rem', md: '1.1rem', lg: '1rem' }}
            fontWeight={'500'}
          >
            Speech-language therapy, also known as speech therapy, is a
            specialized form of therapy that focuses on assessing, diagnosing,
            and treating communication and speech disorders. Speech-language
            therapists, or speech-language pathologists (SLPs), work with
            learners of all ages who experience difficulties with communication,
            speech, language, and related areas. The goal is to help learners
            improve their communication skills and enhance their overall quality
            of life.
          </Text>
          <Button
            size={{ base: 'sm', md: 'md' }}
            color="white"
            _hover={{}}
            bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
            boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
            borderRadius={'5px'}
            fontWeight={'400'}
            onClick={() => nav('/speech-therapy')}
          >
            Learn More
          </Button>
        </Stack>
      
      </Stack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        
      >
          <Stack paddingRight={{ base: 6, md: 5, lg: 6, xl: '3.5vw' }} w={{ base: '100%', md: '50%' }} spacing={6}>
          <Img
            h={{ base: '50vh', lg: '58vh' }}
            borderRadius={'5px'}
            // objectFit={'cover'}
            src={pottheraphy}
          />
          <Heading
            color={'#000000'}
            fontSize={{
              base: '1rem',
              md: '1.3rem',
              lg: '1.3rem',
              xl: '1.5rem',
            }}
            fontWeight={'bold'}
            className="heading"
            lineHeight={'35px'}
          >
            Occupational Therapy
          </Heading>
          <Text
            color={'#343434'}
            fontSize={{ base: '0.75rem', md: '1.1rem', lg: '1rem' }}
            fontWeight={'500'}
          >
            Occupational therapy (OT) is a healthcare profession that focuses on
            helping children develop, regain, or maintain the skills needed for
            daily living and meaningful activities, or occupations. Occupational
            therapists work with learners of all ages who may be facing
            physical, developmental, emotional, or cognitive challenges that
            affect their ability to engage in activities that are important to
            them.
          </Text>
          <Button
            onClick={() => nav('/occupational-Therapy')}
            size={{ base: 'sm', md: 'md' }}
            color="white"
            _hover={{}}
            bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
            boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
            borderRadius={'5px'}
            fontWeight={'400'}
          >
            Learn More
          </Button>
        </Stack>
      
      </Stack>
    </Stack>
  );
};

export default HRServices;
