import React, { useEffect, useState } from 'react';

import {
  Heading,
  Stack,
  Text,
  Img,
  chakra,
  HStack,
  Link,
  Spacer,
} from '@chakra-ui/react';
import Layout from '../layout/Layout';
import pic1 from '../../assets/images/GettyImages.png';
import facebook from '../../assets/images/facebook1.png';
import twitter from '../../assets/images/twitter.png';
import youtube from '../../assets/images/youtube.png';
import { useLocation } from 'react-router-dom';
import { getSingleBlogService } from '../../services/Service';
import moment from 'moment';

const NewsDetail = () => {
  const [blog, setBlog] = useState();

  const location = useLocation();
  console.log('🚀 ~ file: HrNews.js:24 ~ HrNews ~ location:', location?.state);

  useEffect(() => {
    if (location?.state) {
      const getBlogs = async () => {
        const res = await getSingleBlogService(location?.state);
        console.log('🚀 ~ file: HRNews.js:15 ~ getBlogs ~ res:', res);
        if (res.success) {
          setBlog(res.data);
        }
      };
      getBlogs();
    }
  }, []);
  return (
    <Layout>
      <Stack width={'100%'} px={{base:3,md:6,lg:12,xl:20}} py={5}>
        <Stack>
          <Heading
            color={'#000000'}
            fontSize={{ base: '1.5rem', md: '1.4rem', lg: '2.00rem' }}
            fontWeight={'700'}
            pt={1}
          >
            {blog?.title}
          </Heading>
          {/* <Text
            color={'#343434'}
            fontSize={{ base: '0.80rem', md: '0.90rem', lg: '1rem' }}
            fontWeight={'500'}
            lineHeight={{ base: '25px', md: '25px', lg: '25px' }}
          >
            Push to 'reclaim the hoodie' shines light on need 'to get past
            hiring bias'
          </Text> */}

          <Img height='90vh' borderRadius={'xl'} src={blog?.mainContent} />
        </Stack>
        <Stack direction={'row'}>
          <Stack direction={'column'} my={2}>
            <Text
              color={'#17496D'}
              fontSize={{ base: '1.100rem', md: '1.115rem', lg: '1.120rem' }}
              fontWeight={'400'}
              lineHeight={{ base: '8px', md: '10px', lg: '12px' }}
            >
              By <chakra.span color={'#FF6217'}>{blog?.author}</chakra.span>
            </Text>
            <Text
              color={'#17496D'}
              fontSize={{ base: '0.60rem', md: '0.70rem', lg: '0.75rem' }}
              fontWeight={'400'}
            >
              {moment .unix(blog?.timestamp).format('MMM DD, YYYY')} / Share
            </Text>
          </Stack>
          <Spacer />
          <HStack gap={{ base: 2, md: 7 }}>
            <Link href="https://www.facebook.com/">
              <Img w={{ base: '50%', md: '70%', lg: '80%' }} src={facebook} />
            </Link>
            <Link href="https://twitter.com/">
              <Img w={{ base: '50%', md: '70%', lg: '80%' }} src={twitter} />
            </Link>
            <Link href="https://www.youtube.com/">
              <Img w={{ base: '50%', md: '70%', lg: '80%' }} src={youtube} />
            </Link>
          </HStack>
        </Stack>
        <Stack gap={6}>
          <Text
            color={'#343434'}
            fontSize={{ base: '1.00rem', md: '1.10rem', lg: '1.15rem' }}
            fontWeight={'400'}
            lineHeight={{ base: '25px', md: '25px', lg: '30px' }}
          >
            {blog?.description}
          </Text>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default NewsDetail;
