import React from 'react';
import {
  Button,
  Heading,
  Img,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import pic1 from '../../assets/images/team.png';
import { useNavigate } from 'react-router-dom';

const HRTechTrends = ({ blogsData }) => {
  console.log(
    '🚀 ~ file: HRTechTrends.js:7 ~ HRTechTrends ~ blogsData:',
    blogsData
  );
  const nav = useNavigate();
  const truncateText = (text, maxWords) => {
    const truncatedText = text?.split(' ').slice(0, maxWords).join(' ');
    return truncatedText;
  };

  return (
    <Stack id="hr-tech-trends" width={'100%'} py={6}>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        alignItems={'center'}
        // bgColor={'rgba(60, 152, 253, 0.32)'}
        px={{ base: 3, md: 6, lg: 12, xl: 20 }}
        gap={{ base: 12, md: 10, lg: 12, xl: '7vw' }}
      >
        <Stack pb={4} w={{ base: '100%', md: '50%' }}>
          <Heading
            color={'#17496D'}
            fontSize={{ base: '1.3rem', md: '1.8rem', lg: '2rem' }}
            fontWeight={'700'}
            py={{ base: 1, lg: 0 }}
            mt={{ base: 1, lg: 4 }}
            className="heading"
          >
            Our Team
          </Heading>
          {/* <Heading
            className="heading"
            color={'#000000'}
            fontSize={{ base: '1rem', md: '1.3rem', lg: '1.5rem' }}
            fontWeight={'700'}
            lineHeight={'35px'}
          > */}
          {/* {blogsData && blogsData[0]?.title} */}
          {/* Empowering HR Excellence: Navigating the AI Revolution in North
            America */}
          {/* </Heading> */}
          <Text
            color={'#343434'}
            fontSize={{ base: '0.75rem', md: '1rem', lg: '1rem' }}
            fontWeight={'500'}
            py={{ base: 0, lg: 2 }}
          >
            {truncateText(
              `Our team consists of licensed, experienced, and dedicated professionals who are specifically trained to work with our learners. Our diverse team includes:`,
              // "In North America's ever-evolving landscape of Human Resources (HR), the integration of Artificial Intelligence (AI) is fundamentally reshaping the core elements of HR processes, systems, policies, people management, and data utilization. This transformative journey is not about replacing HR practitioners but amplifying their capabilities to navigate a complex and dynamic work environment. In HR processes, the infusion of AI is evident in recruitment and talent acquisition. Automated screening processes powered by AI algorithms are revolutionizing candidate selection, allowing HR practitioners to focus on strategic aspects.",
              50
            )}
          </Text>
          <UnorderedList lineHeight={'27px'}>
            <ListItem>Clinical Director/Supervisor</ListItem>
            <ListItem>
              Board Certified Behavior Analysts (BCBA - Senior Therapists)
            </ListItem>
            <ListItem>
              Registered Behavior Technicians/Instructor Therapists
            </ListItem>
            <ListItem>Speech & Language Pathologists</ListItem>
            <ListItem>Communicative Disorders Assistant</ListItem>
            <ListItem>Licensed Occupational Therapists</ListItem>
            <ListItem>Occupational Therapist Assistants</ListItem>
          </UnorderedList>
          {/* {blogsData?.length > 1 && (
            <Button
              // onClick={() => nav('/hr-techtrends')}
              size="sm"
              color="white"
              _hover={{}}
              bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
              boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
              borderRadius={'5px'}
              fontWeight={'400'}
              w="fit-content"
              px={6}
              mt={3}
            >
              See More
            </Button>
          )} */}
        </Stack>
        <Stack w={{ base: '100%', md: '50%' }} py={20}>
          <Img
            borderRadius={'5px'}
            h="50vh"
            src={pic1}
            w={'fit-content'}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HRTechTrends;
