import React, { useContext, useEffect, useState } from 'react';
import { Heading, Stack, Text, Button, Divider, Img } from '@chakra-ui/react';
import Layout from '../layout/Layout';
import { useLocation, useNavigate } from 'react-router-dom';
import BlogsDataContext from '../../context/BlogsContext';
import { Bars } from 'react-loader-spinner';

const HrTechTrends = () => {
  const { techTrendsBlogs, getBlogsByType } = useContext(BlogsDataContext);
  const nav = useNavigate();
  const [loading, setloading] = useState();


  useEffect(() => {
    setloading(true)
    const getBlogs = async () => {
      const res = await getBlogsByType();
      setloading(false)
    };
    getBlogs();
  }, []);

  function truncateDescription(description, wordCount) {
    if (!description) return '';

    const words = description.split(' ');

    if (words.length <= wordCount) {
      return description;
    } else {
      return words.slice(0, wordCount).join(' ') + '...';
    }
  }
  return (
    <Layout>
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <Bars
              height="60"
              width="60"
              color="#17496D"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}
      <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={5}>
        <Heading
          className="heading"
          color={'#17496D'}
          fontSize={{ base: '1rem', md: '1.4rem', lg: '1.4rem' }}
          fontWeight={'700'}
          mb={1}
        >
          HR Tech Trends
        </Heading>
        <Stack
        spacing={{ base: 6,lg:10,xl:24 }}
        w="100%"
        direction={{ base: 'column', md: 'row' }}
        alignItems={'center'}
      >
        {techTrendsBlogs &&
          techTrendsBlogs[0] &&
          techTrendsBlogs[0].mainContent && (
            <Img
              cursor={'pointer'}
              onClick={() =>
                nav('/techtrends-detail', {
                  state: techTrendsBlogs && techTrendsBlogs[0]
                })
              }
              h={{ base: '40vh', md: '60vh', xl: '50vh' }}
              w={{ base: '100%', md: '50%' }}
              borderRadius={'5px'}
              src={techTrendsBlogs && techTrendsBlogs[0]?.mainContent}
            />
          )}
        <Stack spacing={4} w={{ base: '100%', md: '50%' }}>
          <Heading
            color={'#000000'}
            fontSize={{ base: '1rem', md: '1.3rem', lg: '1.3rem' }}
            fontWeight={'700'}
            pt={1}
          >
            {techTrendsBlogs && techTrendsBlogs[0]?.title}
          </Heading>
          <Text
            color={'#343434'}
            fontSize={{ base: '0.75rem', md: '1.1rem', lg: '1rem'}}
            fontWeight={'500'}
            lineHeight={{ base: '20px', md: '25px', lg: '27px' }}
          >
            {techTrendsBlogs &&
              truncateDescription(techTrendsBlogs[0]?.description, 50)}
          </Text>

          {/* {techTrendsBlogs?.length > 1 && (
            <Stack>
              <Button
                variant="outline"
                borderRadius={'5px'}
                borderColor={'#418DB8'}
                bgColor={'white'}
                color={'#17496D'}
                fontWeight={'500'}
                fontSize={{ base: '1rem', xl: '1.05rem' }}
                _hover={{
                  color: 'white',
                  bgColor: '#418DB8',
                }}
                mx={'32%'}
                mt={8}
                mb={5}
              >
                See More
              </Button>
            </Stack>
          )} */}
        </Stack>
        </Stack>
        {techTrendsBlogs?.map((blog, index) =>
            // Check if it's not the first element (index > 0)
            index > 0 ? (
              <>
                <Divider borderColor={'rgba(151, 151, 151, 1)'} my={3} />

                <Stack
                  w="100%"
                  key={index}
                  // alignItems={'center'}
                  // px={{ base: '5%', xl: '10%' }}
                >
                  <Stack
                    w="100%"
                    direction={{ base: 'column', md: 'row' }}
                    gap={5}
                  >
                    <Img
                      onClick={() =>
                        nav('/techtrends-detail', { state: blog })
                      }
                      w={{ base: '100%', lg: '24%' }}
                      cursor="pointer"
                      h={'165px'}
                      src={blog?.mainContent}
                      borderRadius={'xl'}
                    />
                    <Stack
                      w={{ base: '100%', lg: '75%' }}
                      justifyContent={'center'}
                    >
                      <Heading
                        color={'rgba(0, 0, 0, 0.95)'}
                        fontSize={{ base: '1rem', md: '1.2rem', lg: '1.3rem' }}
                        fontWeight={'700'}
                        // cursor={'pointer'}
                        // onClick={() => nav('techtrends-detail')}
                      >
                        {blog?.title}
                      </Heading>
                      <Text
                        color={'#343434'}
                        fontSize={{ base: '0.75rem', md: '1rem', lg: '1rem' }}
                        fontWeight={'500'}
                      >
                        {truncateDescription(blog?.description, 50)}
                      </Text>
                    </Stack>
                  </Stack>
                  {/* <Divider
                    borderColor={'rgba(151, 151, 151, 1)'}
                    w={'100%'}
                    my={2}
                  /> */}
                </Stack>
              </>
            ) : null
          )}
      </Stack>
    </Layout>
  );
};

export default HrTechTrends;
