import React, { useState, useEffect } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Button, Stack, Text } from '@chakra-ui/react';
import './style.css';
import { useNavigate } from 'react-router-dom';
const PDFViewer = ({ fileUrl }) => {
  const nav = useNavigate()
  const [fileDetails, setFileDetails] = useState({
    uri: fileUrl,
    fileType: '',
    fileName: '',
  });
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  useEffect(() => {
    // Fetch file details dynamically based on the provided file URL
    if (fileUrl) {
      const fileNameWithExtension = fileUrl?.substring(
        fileUrl.lastIndexOf('/') + 1
      );

      // Extract file extension from the filename
      const fileExtension = fileNameWithExtension?.substring(
        fileNameWithExtension.lastIndexOf('.') + 1
      );

      // Remove file extension from the filename
      const fileName = fileNameWithExtension.replace(/\.[^/.]+$/, '');
      setFileDetails({
        uri: 'https://massiveallience.blob.core.windows.net/new-model-data/gayle@massivealliance.com/gayle@massivealliance.com/Gayle Onboarding to ELB/Gayle Sheppard LinkedIn BioProfile.pdf',
        // uri: require('../../assets/files/DLIMS.pdf'),
        uri: fileUrl,
        fileType: fileExtension,
        // fileName: 'Invoice',
        fileName: decodeURIComponent(fileName),
      });
    }
  }, [fileUrl]);

  return (
    <Stack className="doc-container no-select" p={10} alignItems={'center'}>
      <Stack position="relative" w="70%">
        {userInfo?.hasPaid ? null : (
          <div className="subscribe-overlay">
            <div className="download-button">
              <Text fontWeight="600" whiteSpace={'nowrap'} pb={4}>
                Get SnS Pro to view and Download document
              </Text>
              <Button
                alignSelf={'center'}
                px={10}
                w="fit-content"
                size="md"
                color="white"
                _hover={{}}
                bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
                boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
                borderRadius={'5px'}
                fontWeight="400"
                onClick={() => nav('/subscribe')}
              >
                Get SnS Pro
              </Button>
            </div>
          </div>
        )}

        <DocViewer
          documents={[fileDetails]}
          pluginRenderers={DocViewerRenderers}
          style={{ height: 600 }}
          downloadable={false}
        />
      </Stack>
    </Stack>
  );
};

export default PDFViewer;
