import { useToast } from '@chakra-ui/react';
import { baseuRL, localuRL } from '../environment';
const toast = useToast;
// auth module API's
export function signUpUserService(
  firstName,
  lastName,
  phoneNumber,
  state,
  country,
  email,
  password,
  websiteName
) {
  return new Promise((resolve, reject) => {
    fetch(baseuRL + '/api/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName,
        lastName,
        phoneNumber,
        state,
        country,
        email,
        password,
        websiteName,
        // isGoogle: false,
      }),
    })
      .then(response => {
        if (response.status === 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function loginUserService(email, password) {
  return new Promise((resolve, reject) => {
    fetch(baseuRL + '/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        // isGoogle:false,
      }),
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function forgotPasswordService(email) {
  return new Promise((resolve, reject) => {
    fetch(baseuRL + '/api/forgot-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function resetPasswordService(password, token) {
  return new Promise((resolve, reject) => {
    fetch(baseuRL + '/api/reset-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password,
        token,
      }),
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
export function verifyEmailService(token) {
  return new Promise((resolve, reject) => {
    fetch(baseuRL + '/api/verify-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ verificationToken: token }),
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
export function resendEmailService(email) {
  return new Promise((resolve, reject) => {
    fetch(baseuRL + '/api/resend-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getSingleUserService(id) {
  return new Promise((resolve, reject) => {
    fetch(baseuRL + `/api/get-single-user/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
// chat model API
export function SNSChatBotService(text) {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 30000);
  return new Promise((resolve, reject) => {
    fetch(
      `https://74yex9kfzg.execute-api.us-west-2.amazonaws.com/query/HSNW?text=${text}`, // Fix the missing '=' here
      {
        method: 'GET',
        signal: controller.signal,
        redirect: 'follow',
      }
    )
      .then(response => {
        if (response.status === 401) {
          window.location = '/';
          localStorage.clear();
        } else if (response.status === 503) {
          // Handle the 503 error gracefully without showing the error message
          // You can choose to resolve with a predefined value or null
          resolve(null); // Or resolve with a default response
        } else if (response.status >= 500 && response.status < 550) {
          resolve('server error');
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        console.log('Error:', error);
        // Reject the promise to indicate that an error occurred
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function getChatTitleService(text) {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 30000);
  return new Promise((resolve, reject) => {
    fetch(
      `https://wcmgul7q56.execute-api.us-west-2.amazonaws.com/chat_title?text=${text}`, // Fix the missing '=' here
      {
        method: 'GET',
        signal: controller.signal,
        redirect: 'follow',
      }
    )
      .then(response => {
        if (response.status === 401) {
          window.location = '/';
          localStorage.clear();
        } else if (response.status === 503) {
          // Handle the 503 error gracefully without showing the error message
          // You can choose to resolve with a predefined value or null
          resolve(null); // Or resolve with a default response
        } else if (response.status >= 500 && response.status < 550) {
          resolve('server error');
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        console.log('Error:', error);
        // Reject the promise to indicate that an error occurred
        reject(error);
      });
  });
  clearTimeout(tid);
}

////----------------------------- get Blogs APIS---------------------------------------////

export function getBlogsService() {
  return new Promise((resolve, reject) => {
    fetch(baseuRL + '/admin/get-blogs', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getBlogsByTypeService() {
  return new Promise((resolve, reject) => {
    fetch(baseuRL + '/admin/get-blogs-by-type', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

// gte single blog

export function getSingleBlogService(id) {
  return new Promise((resolve, reject) => {
    fetch(baseuRL + `/admin/get-blog/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

// jobs API'S

export function getAllJobsService() {
  return new Promise((resolve, reject) => {
    fetch(baseuRL + '/admin/all-jobs', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
