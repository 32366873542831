import React, { useEffect, useState } from 'react';
import Layout from '../../layout/Layout';
import {
  Button,
  Heading,
  Img,
  Input,
  InputGroup,
  InputRightElement,
  ListItem,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
  chakra,
} from '@chakra-ui/react';
import { Bars } from 'react-loader-spinner';
import { BsSearch } from 'react-icons/bs';
import companyLogo from '../../../assets/images/companyLogo.svg';
import favIcon from '../../../assets/images/favIcon.svg';
import team from '../../../assets/images/team.jpg';
import member1 from '../../../assets/images/member1.jpg';
import member2 from '../../../assets/images/member2.jpg';
import member3 from '../../../assets/images/member3.jpg';
import { getAllJobsService } from '../../../services/Service';
const HrStaggAugmentaion = () => {
  const [jobs, setJobs] = useState();
  const [loading, setloading] = useState();
  useEffect(() => {
    setloading(true);
    getAllJobsService().then(res => {
      setloading(false);
      if (res.success) {
        setJobs(res.jobs);
      }
    });
  }, []);

  return (
    <Layout>
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <Bars
              height="60"
              width="60"
              color="#17496D"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}{' '}
      <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={5}>
        <Heading
          className="heading"
          color={'#17496D'}
          fontSize={{ base: '1.3rem', md: '1.8rem', lg: '2rem' }}
          fontWeight={'700'}
          mb={3}
        >
          Meet Our Team!
        </Heading>

        <Text
          whiteSpace="pre-line"
          color={'#343434'}
          fontSize={{ base: '0.75rem', md: '0.85rem', lg: '0.95rem' }}
          fontWeight={'500'}
          marginBottom={'0'}
        >
          {`We are proud to provide exceptional, evidence-based, and individualized services that are crucial for effectively supporting children with autism spectrum disorder (ASD). Tailoring interventions to meet each child's specific needs can lead to meaningful progress and improved quality of life.`}
        </Text>

        <Stack
        mt={10}
          spacing={{ base: 6, md: 12 }}
          direction={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'baseline', xl: 'flex-start' }}
        >
          <Img
            borderRadius={'5px'}
            h={{ base: '40vh', md: '50vh', lg: '60vh' }}
            w={{ base: '100%', md: '40%' }}
            objectFit={'cover'}
            src={team}
          />
          <Stack w={{ base: '100%', md: '60%' }}>
            <Heading
              color={'#17496D'}
              fontSize={{ base: '1rem', md: '1.8rem', lg: '1.8rem' }}
              fontWeight={'700'}
              className="heading"
              mb={0}
            >
              Samia Nouroze
            </Heading>
            <Text
              color={'#343434'}
              fontSize={{ base: '0.75rem', md: '1rem', lg: '1rem' }}
              fontWeight={'600'}
              marginBottom={3}
            >
              Founder and Director, The Radiant Minds Academy
            </Text>
              
            <Text lineHeight={'1.6'} fontSize={{ base: '0.75rem', md: '0.8rem', lg: '0.8rem' }} >
              Samia Nouroze, a dedicated mother to a young boy named Saim Waqar
              who was diagnosed with autism spectrum disorder (ASD). From the
              moment Saim was diagnosed, Samia embarked on a journey filled with
              challenges, but also with unwavering determination and love. As Saim grew, Samia encountered numerous obstacles in finding suitable educational options for him. She observed the lack of understanding and support for children with ASD in traditional school settings, where Saim struggled to thrive. Witnessing her son's frustration and isolation, Samia knew she had to take action. Driven by her fierce advocacy for Saim and other children like him, Samia made a life-changing decision. She resolved to create a school specifically designed to meet the unique needs of children with autism. With a heart full of hope and a vision for a brighter future, Samia embarked on the monumental task of building her dream school, "The Radiant Minds Academy”. She believes the school can become a haven of acceptance, understanding, and empowerment for children with autism. Each aspect of the curriculum and environment can be carefully designed to foster communication, social skills, independence, and academic achievement.
            </Text>
          </Stack>
        </Stack>
        <Stack my={10} direction='row' alignItems='center' spacing={5}>
          <Stack alignItems={'center'}>
            <Img src={member1}/>
            <Stack marginTop={'-70px'} spacing={0} w='80%'>
              <Stack h='9px' bg='#17496D'></Stack>
              <Stack bg='white' textAlign={'center'} px={4} boxShadow={'2px 5px 40px 0px rgba(0, 0, 0, 0.10)'} h={'100px'} alignItems={'center'} justifyContent={'center'}>
                <Heading className='heading' fontSize={'1rem'} color='#343434'>Coming Soon!</Heading>
                <Text fontWeight={'400'} fontSize={'0.7rem'}>Board Certified Behaviour Analyst (BCBA)</Text>
              </Stack>
            </Stack>
          </Stack>
          <Stack alignItems={'center'}>
            <Img src={member2}/>
            <Stack marginTop={'-70px'} spacing={0} w='80%'>
              <Stack h='9px' bg='#17496D'></Stack>
              <Stack bg='white' textAlign={'center'} px={4} boxShadow={'2px 5px 40px 0px rgba(0, 0, 0, 0.10)'} h={'100px'} alignItems={'center'} justifyContent={'center'}>
                <Heading className='heading' fontSize={'1rem'} color='#343434'>Coming Soon!</Heading>
                <Text fontWeight={'400'} fontSize={'0.7rem'}>Speech-Language Pathologist (SLP)</Text>
              </Stack>
            </Stack>
          </Stack>
          <Stack alignItems={'center'}>
            <Img src={member3}/>
            <Stack marginTop={'-70px'} spacing={0} w='80%'>
              <Stack h='9px' bg='#17496D'></Stack>
              <Stack bg='white' textAlign={'center'} px={4} boxShadow={'2px 5px 40px 0px rgba(0, 0, 0, 0.10)'} h={'100px'} alignItems={'center'} justifyContent={'center'}>
                <Heading className='heading' fontSize={'1rem'} color='#343434'>Coming Soon!</Heading>
                <Text fontWeight={'400'} fontSize={'0.7rem'}>Occupational Therapist (OT)</Text>
              </Stack>
            </Stack>
          </Stack>

        </Stack>
      </Stack>
    </Layout> 
  );
};

export default HrStaggAugmentaion;
