  import React from 'react';
  import Layout from '../../layout/Layout';
  import { Button, Heading, Image, Img, Stack, Text } from '@chakra-ui/react';
  import st from '../../../assets/images/st.jpeg';
  import contactus from '../../../assets/images/contactus.svg';
  import { useNavigate } from 'react-router-dom';

  const HrDigitalTranformation = () => {

    const nav = useNavigate();
    return (
      <Layout>
        <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={6}>
          <Heading
            color={'#17496D'}
            fontSize={{ base: '1rem', md: '1.8rem', lg: '2rem' }}
            fontWeight={'700'}
            className="heading"
            mb={8}
          >
             Our Programs
          </Heading>
          <Stack
            gap={{ base: 12, md: 10, lg: 12, xl: '7vw' }}
            direction={{ base: 'column', md: 'row' }}
          >
            <Img
              w={{ base: '100%', md: '50%' }}
              h={{ base: '40vh', md: '50vh', lg: '60vh' }}
              src={st}
              borderRadius={'5px'}
            />
            <Stack gap={0}>
              {/* <Text fontWeight={'600'} fontSize={'15px'} color="#FF6217">
                HR Digital Transformation Services
              </Text> */}
              <Heading
                color={'#17496D'}
                fontSize={{ base: '1rem', md: '1.8rem', lg: '1.8rem' }}
                fontWeight={'700'}
                className="heading"
                mt={2}
                mb={4}
              >
                Speech Therapy
              </Heading>
              <div>
                <div>
                  Speech-language therapy, also known as speech therapy, is a
                  specialized form of therapy that focuses on assessing,
                  diagnosing, and treating communication and speech disorders.
                  Speech-language therapists, or speech-language pathologists
                  (SLPs), work with learners of all ages who experience
                  difficulties with communication, speech, language, and related
                  areas. The goal is to help learners improve their communication
                  skills and enhance their overall quality of life.
                </div>
                <br />
                <div>Key aspects of speech-language therapy include:</div>
                <br />
                <div>
                  <strong style={{ color: '#17496D' }}>
                    1- Speech Disorders:{' '}
                  </strong>{' '}
                  Speech therapy addresses speech disorders, which involve
                  difficulties with the physical production of sounds. This
                  includes articulation disorders, phonological disorders, and
                  fluency disorders (such as stuttering). Therapists help learners
                  produce clear and intelligible speech.
                </div>
                <br />
                <div>
                  <strong style={{ color: '#17496D' }}>
                    2- Language Disorders:{' '}
                  </strong>{' '}
                  Language disorders involve difficulties with understanding and
                  using language. This can include problems with vocabulary,
                  grammar, comprehension, and expression. Speech-language
                  therapists work on improving both receptive (understanding) and
                  expressive (speaking or writing) language skills.
                </div>
                <br />
                <div>
                  <strong style={{ color: '#17496D' }}>
                    3- Communication Disorders:{' '}
                  </strong>{' '}
                  Speech therapy addresses a range of communication disorders,
                  including difficulties with social communication, pragmatics,
                  and the ability to engage in effective and appropriate
                  conversations.
                </div>
                <br />
                <div>
                  <strong style={{ color: '#17496D' }}>
                    4- Voice Disorders:{' '}
                  </strong>{' '}
                  Speech-language pathologists can help learners with voice
                  disorders, which may include problems with pitch, volume, or
                  quality of the voice. This can result from various conditions,
                  such as nodules on the vocal cords or vocal cord paralysis.
                </div>
                <br />
                <div>
                  <strong style={{ color: '#17496D' }}>
                    5- Fluency Disorders:{' '}
                  </strong>{' '}
                  Speech therapists work with learners who experience disruptions
                  in the flow of speech, known as fluency disorders. Stuttering is
                  a common example of a fluency disorder, and therapy aims to
                  improve the smoothness and rhythm of speech.
                </div>
                <br />

                <div>
                  <strong style={{ color: '#17496D' }}>
                    6- Cognitive-Communication Disorders:{' '}
                  </strong>{' '}
                  Learners who experience challenges with cognitive-communication
                  skills, such as memory, problem-solving, and attention, may
                  benefit from speech therapy to improve their overall
                  communication abilities.
                </div>
                <br />

                <div>
                  <strong style={{ color: '#17496D' }}>
                    7- Swallowing Disorders:{' '}
                  </strong>{' '}
                  Speech-language pathologists also play a role in the assessment
                  and treatment of swallowing disorders (dysphagia). This involves
                  difficulties with the physical process of swallowing, which can
                  occur due to various medical conditions.
                </div>
                <br />

                <div>
                  Speech-language therapy sessions are tailored to the specific
                  needs of the learner. Therapists use a variety of techniques,
                  exercises, and activities to target the identified areas of
                  concern. The overall goal is to help individuals communicate
                  effectively, improve their speech and language skills, and
                  overcome any barriers that may be impacting their ability to
                  interact with others and participate fully in daily life.
                </div>
              </div>
              {/* <Text
                color={'#343434'}
                fontSize={{ base: '0.75rem', md: '0.85rem', lg: '0.95rem' }}
                fontWeight={'500'}
                marginBottom={'0'}
                whiteSpace="pre-line"
                lineHeight={{ base: '23px', lg: '27px' }}
              >
                {text}
              </Text> */}
              <Button
                mt={3}
                onClick={() => nav('/contact-us')}
                size={{ base: 'sm', md: 'lg' }}
                leftIcon={<Image src={contactus} />}
                color="white"
                _hover={{}}
                bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
                boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
                borderRadius={'5px'}
                fontWeight={'400'}
                w="fit-content"
              >
                Work with us
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Layout>
    );
  };

  export default HrDigitalTranformation;
