import React, { useContext, useEffect, useState } from 'react';
import {
  Heading,
  Stack,
  Text,
  Button,
  Divider,
  Img,
  chakra,
  HStack,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import Layout from '../layout/Layout';
import i from '../../assets/images/i.svg';
import pic1 from '../../assets/images/pdf.png';
import pptx from '../../assets/images/pptx.svg';
import xls from '../../assets/images/xls.svg';
import docx from '../../assets/images/docx.svg';
import pic2 from '../../assets/images/docx.png';
import pic3 from '../../assets/images/pptx.png';
import pic4 from '../../assets/images/xls.png';
import pic5 from '../../assets/images/txt.png';
import pic6 from '../../assets/images/xer.png';
import pic7 from '../../assets/images/jpg.png';
import { useNavigate } from 'react-router-dom';
import BlogsDataContext from '../../context/BlogsContext';
import { Bars } from 'react-loader-spinner';

const Toolkits = () => {
  const nav = useNavigate();

  const [loading, setloading] = useState();
  const [toolkits, setToolkits] = useState([]);
  const { getBlogsByType } = useContext(BlogsDataContext);

  useEffect(() => {
    setloading(true);
    const getBlogs = async () => {
      const res = await getBlogsByType();
      const toolkitsArray =
        res?.data?.hrResources?.filter(blog => blog.category === 'Toolkits') ||
        [];

      setToolkits(toolkitsArray);
      setloading(false);
    };
    getBlogs();
  }, []);

  function truncateUrl(url, maxLength) {
    if (url?.length <= maxLength) {
      return url;
    } else {
      const truncatedUrl = url?.substring(0, maxLength - 3) + '...';
      return truncatedUrl;
    }
  }
  return (
    <Layout>
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <Bars
              height="60"
              width="60"
              color="#17496D"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}
      <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={5}>
        <Heading
          classname="heading"
          color={'#17496D'}
          fontSize={{ base: '1.3rem', md: '1.8rem', lg: '2rem' }}
          fontWeight={'700'}
          mb={6}
        >
          Occupational Therapy Resources
        </Heading>

        <Text
          whiteSpace="pre-line"
          color={'#343434'}
          fontSize={{ base: '0.75rem', md: '0.85rem', lg: '0.95rem' }}
          fontWeight={'500'}
          marginBottom={'0'}
        >
          {`Everyone’s needs and circumstances are unique, requiring tailored approaches for optimal outcomes. Working collaboratively with a skilled occupational therapist ensures that interventions are evidence-based, effective, and aligned with the learner’s goals and preferences. Below are resources that can provide valuable information and insights into occupational therapy practice.`}
        </Text>
        <div style={{ fontWeight: '600', marginTop: '10PX' }}>
          American Occupational Therapy Association (AOTA):{' '}
        </div>
        <ul style={{ marginLeft: '10px' }}>
          <li style={{ marginBottom: '10px' }}>
            AOTA is the national professional association for occupational
            therapists, occupational therapy assistants, and students of
            occupational therapy in the United States. Their website{' '}
            <span
              onClick={() => window.open(`https://www.aota.org/`, '_blank')}
              style={{ color: '#418DB8', cursor: 'pointer' }}
            >
              (https://www.aota.org/)
            </span>{' '}
            offers a wealth of resources, including information about
            occupational therapy practice, evidence-based guidelines, continuing
            education opportunities, and advocacy efforts.
          </li>
        </ul>
        <div style={{ fontWeight: '600', marginTop: '10PX' }}>Books</div>
        <ul style={{ marginLeft: '10px' }}>
          <li style={{ marginBottom: '10px' }}>
            "Occupational Therapy for Physical Dysfunction" by Catherine Trombly
            Latham: This comprehensive textbook covers evaluation and
            intervention strategies for individuals with physical disabilities,
            including orthopedic conditions, neurological disorders, and chronic
            pain.
          </li>
          <li style={{ marginBottom: '10px' }}>
            "Willard and Spackman's Occupational Therapy" edited by Barbara A.
            Boyt Schell, Glen Gillen, and Ellen S. Cohn: This widely used
            textbook covers the foundations of occupational therapy practice,
            including theory, assessment, and intervention across various
            practice settings.
          </li>
          <li style={{ marginBottom: '10px' }}>
            "Occupational Therapy in Mental Health: A Vision for Participation"
            by Catana Brown and Virginia C. Stoffel: This book focuses on the
            role of occupational therapy in mental health settings, addressing
            assessment and intervention approaches for individuals with
            psychiatric disorders.
          </li>
        </ul>
        <div style={{ fontWeight: '600', marginTop: '10PX' }}>Websites:</div>
        <ul style={{ marginLeft: '10px' }}>
          <li style={{ marginBottom: '10px' }}>
            World Federation of Occupational Therapists (WFOT):{' '}
            <span
              onClick={() => window.open(`https://www.wfot.org/`, '_blank')}
              style={{ color: '#418DB8', cursor: 'pointer' }}
            >
              <a>https://www.wfot.org/</a>
            </span>
            <br />
            WFOT is the international organization representing occupational
            therapists worldwide. Their website offers resources, news updates,
            and information about occupational therapy practice around the
            world.
          </li>

          <li style={{ marginBottom: '10px' }}>
            Occupational Therapy website:{' '}
            <span
              onClick={() =>
                window.open(`https://occupationaltherapy.com`, '_blank')
              }
              style={{ color: '#418DB8', cursor: 'pointer' }}
            >
              <a>https://occupationaltherapy.com</a>
            </span>
            <br />
            This website offers online courses, webinars, and resources for
            occupational therapists, covering a wide range of topics such as
            pediatric therapy, geriatric care, and assistive technology.
          </li>
        </ul>
        <div style={{ fontWeight: '600', marginTop: '10PX' }}>
          Journals/Articles
        </div>
        <ul style={{ marginLeft: '10px' }}>
          <li style={{ marginBottom: '10px' }}>
            American Journal of Occupational Therapy (AJOT): This is the
            official peer-reviewed journal of AOTA, publishing research
            articles, clinical practice guidelines, and case studies related to
            occupational therapy.
          </li>
          <li style={{ marginBottom: '10px' }}>
            British Journal of Occupational Therapy (BJOT): This journal covers
            research and practice issues in occupational therapy, including
            articles on assessment methods, intervention approaches, and
            professional development.
          </li>
        </ul>
      </Stack>
    </Layout>
  );
};

export default Toolkits;
