import React, { useContext, useEffect, useState } from 'react';
import {
  Heading,
  Stack,
  Text,
  Button,
  Divider,
  Img,
  chakra,
  HStack,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import Layout from '../layout/Layout';
import i from '../../assets/images/i.svg';
import pic1 from '../../assets/images/pdf.png';
import pptx from '../../assets/images/pptx.svg';
import xls from '../../assets/images/xls.svg';
import docx from '../../assets/images/docx.svg';
import pic2 from '../../assets/images/docx.png';
import pic3 from '../../assets/images/pptx.png';
import pic4 from '../../assets/images/xls.png';
import pic5 from '../../assets/images/txt.png';
import pic6 from '../../assets/images/xer.png';
import pic7 from '../../assets/images/jpg.png';
import { useNavigate } from 'react-router-dom';
import BlogsDataContext from '../../context/BlogsContext';
import { Bars } from 'react-loader-spinner';

const HrProcessTemplate = () => {
  const [loading, setloading] = useState();
  const [processTemplates, setprocessTemplates] = useState([]);
  console.log('🚀 ~ HrProcessTemplate ~ processTemplates:', processTemplates);
  const { getBlogsByType } = useContext(BlogsDataContext);
  const nav = useNavigate();

  useEffect(() => {
    setloading(true);
    const getBlogs = async () => {
      const res = await getBlogsByType();
      const toolkitsArray =
        res?.data?.hrResources?.filter(
          blog => blog.category === 'HR Process Templates'
        ) || [];

      setprocessTemplates(toolkitsArray);
      setloading(false);
    };
    getBlogs();
  }, []);
  function truncateUrl(url, maxLength) {
    if (url?.length <= maxLength) {
      return url;
    } else {
      const truncatedUrl = url?.substring(0, maxLength - 3) + '...';
      return truncatedUrl;
    }
  }
  return (
    <Layout>
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <Bars
              height="60"
              width="60"
              color="#17496D"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}
      <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={5}>
        <Heading
          classname="heading"
          color={'#17496D'}
          fontSize={{ base: '1.3rem', md: '1.8rem', lg: '2rem' }}
          fontWeight={'700'}
          mb={6}
        >
          Speech Therapy Resources
        </Heading>

        <Text
          whiteSpace="pre-line"
          color={'#343434'}
          fontSize={{ base: '0.75rem', md: '0.85rem', lg: '0.95rem' }}
          fontWeight={'500'}
          marginBottom={'0'}
        >
          {`As always, it's important to consult with a qualified speech-language pathologist for personalized assessment and intervention recommendations for your child. Below are resources that should provide you with a comprehensive understanding of speech therapy and its applications across different settings.`}
        </Text>
        <div style={{ fontWeight: '600', marginTop: '10PX' }}>
          American Speech-Language-Hearing Association (ASHA)
        </div>
        <ul style={{ marginLeft: '10px' }}>
          <li style={{ marginBottom: '10px' }}>
            ASHA is the professional organization for speech-language
            pathologists and audiologists. Their website{' '}
            <span
              onClick={() => window.open(`https://www.asha.org/`, '_blank')}
              style={{ color: '#418DB8', cursor: 'pointer' }}
            >
              (https://www.asha.org/)
            </span>{' '}
            offers a wealth of resources, including information about speech and
            language disorders, evidence-based practices, continuing education
            opportunities, and finding a speech-language pathologist in your
            area.
          </li>
        </ul>
        <div style={{ fontWeight: '600', marginTop: '10PX' }}>Books</div>
        <ul style={{ marginLeft: '10px' }}>
          <li style={{ marginBottom: '10px' }}>
            "Introduction to Communication Disorders: A Lifespan Evidence-Based
            Perspective" by Robert E. Owens Jr.: This textbook provides an
            overview of communication disorders across the lifespan and covers
            assessment and intervention strategies used in speech therapy.
          </li>
          <li style={{ marginBottom: '10px' }}>
            "The Source for Dysphagia" by Nancy B. Swigert and Colleen K.
            Worthington: This book focuses specifically on dysphagia (swallowing
            disorders) and provides practical information for speech-language
            pathologists working in this area.
          </li>
          <li style={{ marginBottom: '10px' }}>
            "The Late Eight" by M.N. Hegde: This book focuses on communication
            disorders in adults and the elderly, including assessment and
            treatment techniques.
          </li>
        </ul>
        <div style={{ fontWeight: '600', marginTop: '10PX' }}>Websites:</div>
        <ul style={{ marginLeft: '10px' }}>
          <li style={{ marginBottom: '10px' }}>
            National Institute on Deafness and Other Communication Disorders
            (NIDCD):{' '}
            <span
              onClick={() =>
                window.open(`https://www.nidcd.nih.gov/`, '_blank')
              }
              style={{ color: '#418DB8', cursor: 'pointer' }}
            >
              <a>https://www.nidcd.nih.gov/</a>
            </span>
            <br />
            This website offers information about various communication
            disorders, including research updates, treatment options, and
            resources for individuals and families.
          </li>

          <li style={{ marginBottom: '10px' }}>
            Speech Pathology website:{' '}
            <span
              onClick={() =>
                window.open(`https://www.speechpathology.com`, '_blank')
              }
              style={{ color: '#418DB8', cursor: 'pointer' }}
            >
              <a>https://www.speechpathology.com</a>
            </span>
            <br />
            This website offers online courses, webinars, and resources for
            speech-language pathologists, covering a wide range of topics in
            speech therapy.
          </li>
        </ul>
        <div style={{ fontWeight: '600', marginTop: '10PX' }}>Journals/Articles</div>
        <ul style={{ marginLeft: '10px' }}>
          <li style={{ marginBottom: '10px' }}>
          Journal of Speech, Language, and Hearing Research (JSLHR): This is a leading journal in the field of speech-language pathology, publishing research articles on communication disorders, assessment methods, and intervention techniques.
          </li>
          <li style={{ marginBottom: '10px' }}>
          American Journal of Speech-Language Pathology (AJSLP): This journal covers clinical practice issues and research related to speech-language pathology, including assessment and treatment of communication disorders.
          </li>
        </ul>
      </Stack>
    </Layout>
  );
};

export default HrProcessTemplate;
