import React, { useState } from 'react';
import {
  Box,
  Flex,
  Image,
  Heading,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Link,
  Stack,
  Img,
  useToast,
  InputLeftAddon,
  FormControl,
  Select,
} from '@chakra-ui/react';
import { EmailIcon, LockIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import signupImage from '../../assets/images/signup.png';
import logo from '../../assets/images/logowhite.png';
import './Signup.css';
import { useNavigate } from 'react-router-dom';
import { signUpUserService } from '../../services/Service';
import { UsaStates, provinces } from '../../utils/states';
const SignupPage = () => {
  const toast = useToast();
  const nav = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setloading] = useState(false);
  const [name, setName] = useState('');
  const [state, setstate] = useState('');
  const [country, setcountry] = useState('USA');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [password, setPassword] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [phoneNumberError, setphoneNumberError] = useState('');
  const [submitClicked, setSubmitClicked] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const handleSelectChange = event => {
    setcountry(event.target.value);
  };
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleNameChange = event => {
    const newName = event.target.value;
    setName(newName);
  };
  const handleEmailChange = event => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };
  const handlePasswordChange = event => {
    const newPassword = event.target.value;
    setPassword(newPassword);
  };
  const handleCountryCodeChange = event => {
    setCountryCode(event.target.value);
  };

  const handlePhoneNumberChange = event => {
    setPhoneNo(event.target.value);
  };
  let isValidPhoneNumber;

  const handleSubmit = async event => {
    event.preventDefault();
    setSubmitClicked(true);

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s).{8,}$/;

    const isValidEmail = emailRegex.test(email);
    const isValidPassword = passwordRegex.test(password);
    const isInvalidPhoneNo = phoneNo && phoneNo.length !== 12 ?true:false

    if (!isValidEmail) {
      setEmailError('Invalid email address.');
    } else {
      setEmailError('');
    }
    if (isInvalidPhoneNo) {
      setphoneNumberError('Please enter 10 digits phone number');
    } else {
      setphoneNumberError('');
    }

    if (!isValidPassword) {
      setPasswordError(
        'Password must contain at least 8 characters, one uppercase, one lowercase, and one digit.'
      );
    } else {
      setPasswordError('');
    }

    if (isValidEmail && isValidPassword && !isInvalidPhoneNo) {
      setloading(true);
      // Handle successful form submission
      console.log('Form submitted successfully.');
      const res = await signUpUserService(
        name,
        lastName,
        phoneNo,
        state,
        country,
        email,
        password,
        "Hr"
      );
      console.log('🚀 ~ file: SignUpPage.js:91 ~ handleSubmit ~ res:', res);
      if (res.success) {
        setloading(false);
        toast({
          title: res?.message,
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        nav('/resend-email',{state:email});
        setName('');
        setEmail('');
        setPassword('');
      } else {
        setloading(false);
        toast({
          title: res.message,
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } else {
      console.log('Form has errors. Please correct them.');
    }
  };
  const handleInputChange = event => {
    const inputNumber = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
    const formatted = inputNumber
      .replace(/(\d{3})(\d{3})(\d{0,4})/, '$1 $2 $3')
      .trim();

    setPhoneNo(formatted);
  };
  const isSubmitDisabled =
    !name || !lastName || !email || !password || !phoneNo || !country || !state;

  return (
    <Stack gap={0} alignItems={'center'}>
      <Stack
        w="100%"
        justifyContent={'center'}
        direction="row"
        bg={
          'var(--gradient, linear-gradient(15deg, #17496D 0%, #418DB8 92.71%))'
        }
        height={'7vh'}
        alignItems={'center'}
        boxShadow={'0px 8px 30px 0px rgba(13, 24, 33, 0.08)'}
      >
        <Img w={6} src={logo} />
        <Text fontSize={'lg'} color="white">
          SnSHRhub
        </Text>
      </Stack>
      <Stack
        // className="signup-container"
        w="100%"
        maxW={'100%'}
        overflowX={'hidden'}
        direction={{ base: 'column-reverse', md: 'row' }} // Reversed on mobile for better flow
        justifyContent="center"
        alignItems="center"
        // minH={'93vh !important'}
      >
        {/* Image Section */}
        <Flex
          display={{ base: 'none', md: 'flex' }} // Hide on mobile
          width="50%"
          justifyContent="center"
          alignItems="center"
        >
          <Image
            src={signupImage}
            alt="Signup Image"
            objectFit="cover"
            width="60%"
          />
        </Flex>
        {/* Form Section */}
        <Flex
          px={{ base: 6, sm: 8, md: 12, lg: 16 }}
          py={8}
          h={'full'}
          bg={{ base: 'transparent', md: '#F8FBFF' }}
          width={{ base: '100%', md: '50%' }}
          textAlign="left"
          align="center"
        >
          <Stack width="100%" spacing="4">
            <Stack>
              <Text fontWeight="600" fontSize="xl" color="#196EC9">
                SignUp
              </Text>
              <Text>
                Welcome to SnSHRhub! Join Us by Signing Up Today!
              </Text>
            </Stack>
            <form style={{ width: '100%' }} onSubmit={handleSubmit}>
              <Stack spacing={6}>
                {/* Input Fields */}
                <Stack
                  justifyContent={'space-between'}
                  direction={{ base: 'column', md: 'row' }}
                >
                  <Stack w={{ base: '100%', md: '49%' }} spacing={2}>
                    <Text fontWeight="600" fontSize="sm">
                      First Name
                    </Text>
                    <Input
                      borderRadius="full"
                      bg="white"
                      type="text"
                      placeholder="Enter first name"
                      value={name}
                      onChange={handleNameChange}
                    />
                    {/* {nameError && (
                    <Text color="red" fontSize="sm">
                      {nameError}
                    </Text>
                  )} */}
                  </Stack>
                  <Stack w={{ base: '100%', md: '49%' }} spacing={2}>
                    <Text fontWeight="600" fontSize="sm">
                      Last Name
                    </Text>
                    <Input
                      borderRadius="full"
                      bg="white"
                      type="text"
                      placeholder="Enter last name"
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                    />
                    {/* {nameError && (
                    <Text color="red" fontSize="sm">
                      {nameError}
                    </Text>
                  )} */}
                  </Stack>
                </Stack>
                <Stack
                  justifyContent={'space-between'}
                  direction={{ base: 'column', md: 'row' }}
                >
                  <Stack w={{ base: '100%', md: '49%' }} spacing={2}>
                    <Text fontWeight="600" fontSize="sm">
                      Country
                    </Text>
                    <Select
                      value={country}
                      onChange={handleSelectChange}
                      cursor="pointer"
                      bg="white"
                      borderRadius={'full'}
                      placeholder="Select country"
                      sx={{
                        '& option:first-child': {
                          display: 'none',
                        },
                      }}
                    >
                      <option value="Canada">Canada</option>
                      <option value="USA">USA</option>
                    </Select>
                  </Stack>
                  <Stack w={{ base: '100%', md: '49%' }} spacing={2}>
                    <Text fontWeight="600" fontSize="sm">
                      {country == 'USA' ? 'States' : 'Provinces'}
                    </Text>
                    <Select
                      value={state}
                      onChange={e => setstate(e.target.value)}
                      cursor="pointer"
                      bg="white"
                      borderRadius={'full'}
                      placeholder={
                        country == 'USA' ? 'Select State' : 'Select Province'
                      }
                      sx={{
                        '& option:first-child': {
                          display: 'none',
                        },
                      }}
                    >
                      {country == 'USA'
                        ? UsaStates?.map(state => (
                            <option value={state}>{state}</option>
                          ))
                        : provinces?.map(state => (
                            <option value={state}>{state}</option>
                          ))}
                    </Select>
                  </Stack>
                </Stack>
                <Stack spacing={2}>
                  <Text fontWeight="600" fontSize="sm">
                    Phone No
                  </Text>
                  <Input
                    borderRadius="full"
                    bg="white"
                    type="text"
                    placeholder="142 887 9837"
                    value={phoneNo}
                    onChange={handleInputChange}
                    maxLength={12} // 3+3+4+2 (for spaces)
                  />
                  {phoneNumberError && (
                    <Text color="red" fontSize="sm">
                      {phoneNumberError}
                    </Text>
                  )}
                </Stack>
                <Stack spacing={2}>
                  <Text fontWeight="600" fontSize="sm">
                    Email Address
                  </Text>
                  <Input
                    borderRadius="full"
                    bg="white"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailChange}
                  />

                  {emailError && (
                    <Text color="red" fontSize="sm">
                      {emailError}
                    </Text>
                  )}
                </Stack>
                <Stack spacing={2}>
                  <Text fontWeight="600" fontSize="sm">
                    Password
                  </Text>
                  <InputGroup>
                    <Input
                      borderRadius="full"
                      bg="white"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <InputRightElement cursor="pointer">
                      {showPassword ? (
                        <ViewOffIcon
                          color="gray.300"
                          onClick={handlePasswordVisibility}
                        />
                      ) : (
                        <ViewIcon
                          color="gray.300"
                          onClick={handlePasswordVisibility}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  {passwordError && (
                    <Text color="red" fontSize="sm">
                      {passwordError}
                    </Text>
                  )}
                </Stack>
                {/* Signup Button */}
                <Button
                  isLoading={loading}
                  w="100%"
                  fontWeight="500"
                  color="white"
                  borderRadius="full"
                  bg="var(--gradient, linear-gradient(15deg, #17496D 0%, #418DB8 92.71%))"
                  _hover={{}}
                  type="submit"
                  isDisabled={isSubmitDisabled}
                >
                  Signup
                </Button>
              </Stack>
            </form>
            {/* Login Link */}
            <Text textAlign="center">
              Already have an account?{' '}
              <Link onClick={() => nav('/')} color="#3C98FD">
                Login
              </Link>
            </Text>
          </Stack>
        </Flex>
      </Stack>
    </Stack>
  );
};

export default SignupPage;
