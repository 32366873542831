import React from 'react';
import Layout from '../layout/Layout';
import { Heading, Img, Stack, Text } from '@chakra-ui/react';
import hrtransformation from '../../assets/images/hrtransformation.jpg';
import children from '../../assets/images/children.jpg';
import hands from '../../assets/images/clay.jpg';

const AboutUs = () => {
  const whoWeAreText = `The Radiant Minds Academy is a school for children diagnosed with autism spectrum disorder (ASD). It stands as a beacon of hope and support for families touched by evidence-based services. Our commitment to service excellence encompasses therapy, education, research, and awareness, addressing the unique needs of children and youth, along with their families.

  At the heart of our mission is the provision of intensive, comprehensive, and individualized therapeutic and educational programs. Guided by the principles of Applied Behaviour Analysis (ABA), Speech Therapy and Occupational Therapy. We strive to make a meaningful impact on the lives of our learners.
  
  We firmly believe that every child with autism deserves the opportunity to unlock their fullest potential. Our approach is characterized by personal care and nurturing interactions, ensuring that each learner can experience maximum individual development. Within our warm, welcoming, safe, and comfortable environment, we foster an atmosphere conducive to growth and learning. Together, we embark on a journey towards empowering every child to thrive and reach new skills.`;
  const ourVision = `Our vision is anchored in the belief that every autistic child deserves to be empowered, confident, and equipped to embrace their full potential. At Radiant Minds Academy, we aspire to be a shining beacon of inclusive education, dedicated to nurturing not only academic growth but also the holistic development of each child's social, emotional, and physical well-being.\n\nIn our envisioned learning environment, diversity is celebrated, and independence is encouraged, creating a space where every child feels a sense of belonging. Our goal is to instill in each student a lifelong love for learning, fostering a spirit of curiosity and resilience that will guide them on their journey toward a bright and fulfilling future. At Radiant Minds Academy, we are committed to shaping a world where all children's unique abilities are recognized and celebrated.
  `;
  return (
    <Layout>
      <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={6}>
        <Heading
          color={'#17496D'}
          fontSize={{ base: '1rem', md: '1.8rem', lg: '2rem' }}
          fontWeight={'700'}
          className="heading"
          mb={6}
        >
          About Us
        </Heading>
        <Stack
          spacing={{ base: 6, md: 12 }}
          direction={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'baseline', xl: 'flex-start' }}
        >
          <Img
            borderRadius={'5px'}
            h={{ base: '40vh', md: '50vh', lg: '60vh' }}
            w={{ base: '100%', md: '50%' }}
            src={hands}
          />
          <Stack w={{ base: '100%', md: '50%' }}>
            <Heading
              color={'#17496D'}
              fontSize={{ base: '1rem', md: '1.8rem', lg: '1.8rem' }}
              fontWeight={'700'}
              className="heading"
              mb={3}
            >
              Who We Are?
            </Heading>
            <Text
              color={'#343434'}
              fontSize={{ base: '0.75rem', md: '0.85rem', lg: '0.95rem' }}
              fontWeight={'500'}
              marginBottom={'0'}
              whiteSpace={'pre-line'}
            >
              {whoWeAreText}
            </Text>
          </Stack>
        </Stack>
        <Stack
          mt={16}
          spacing={{ base: 6, md: 12 }}
          direction={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'baseline', xl: 'flex-start' }}
        >
          <Stack w={{ base: '100%', md: '50%' }} pt={3}>
            <Heading
              color={'#17496D'}
              fontSize={{ base: '1rem', md: '1.8rem', lg: '1.8rem' }}
              fontWeight={'700'}
              className="heading"
              mb={6}
            >
              Our Mission
            </Heading>
            <Text
              color={'#343434'}
              fontSize={{ base: '0.75rem', md: '0.85rem', lg: '0.95rem' }}
              fontWeight={'500'}
              marginBottom={'0'}
              whiteSpace={'pre-line'}
            >
              {`Radiant Minds Academy is dedicated to embracing a holistic and eclectic approach in the education and development of children with autism. Our methodology is rooted in the integration of evidence-based practices, tailored learning plans, and the creation of a sensory-friendly environment.

The heart of our curriculum lies in the incorporation of play-based learning, fostering not only academic growth but also enhancing social skills development. Additionally, we seamlessly integrate technology to provide a well-rounded educational experience that aligns with the diverse needs of our learners.

Recognizing the pivotal role of parents in the journey of their children, we emphasize active parent involvement. Our commitment extends to continuous professional development, ensuring our educators are equipped with the latest tools and techniques. At the core of our approach is the implementation of positive behavior support, creating a dynamic and adaptive educational experience that nurtures the unique strengths and abilities of each child at Radiant Minds Academy.
`}
            </Text>
            <Heading
              pt={3}
              color={'#17496D'}
              fontSize={{ base: '1rem', md: '1.8rem', lg: '1.8rem' }}
              fontWeight={'700'}
              className="heading"
              mb={6}
            >
              Our Vision
            </Heading>
            <Text
              color={'#343434'}
              fontSize={{ base: '0.75rem', md: '0.85rem', lg: '0.95rem' }}
              fontWeight={'500'}
              whiteSpace={'pre-line'}
            >
              {ourVision}
              <Heading
                 pt={3}
                 color={'#17496D'}
                 fontSize={{ base: '1rem', md: '1.8rem', lg: '1.8rem' }}
                 fontWeight={'700'}
                 className="heading"
                 my={6}

                textAlign={'start'}
              >
                Our Values
              </Heading>
              <div>
                <div>
                  <strong style={{color:'#17496D'}}>1- Accountability: </strong> We embrace a culture of
                  accountability, taking ownership of our actions. Seeking
                  stakeholder feedback, we are committed to continuous
                  improvement, maintaining transparency, professionalism, and
                  ethical conduct in all facets of our services.
                </div>
                <br />
                <div>
                  <strong style={{color:'#17496D'}}>2- Collaboration: </strong> We believe in the power of
                  collaboration. The best clinical care emerges through
                  cooperative efforts involving individuals, families,
                  healthcare providers, and other stakeholders. By working
                  together, we can design and deliver services that truly meet
                  the diverse needs of our community.
                </div>
                <br />

                <div>
                  <strong style={{color:'#17496D'}}>3- Evidence-informed: </strong> Our commitment to
                  excellence is evident in our dedication to being
                  evidence-informed. We continually evaluate and enhance our
                  services based on the latest research, striving for innovation
                  in the design of clinical services that contribute to the
                  advancement of our sector.
                </div>
                <br />

                <div>
                  <strong style={{color:'#17496D'}}>4- Inclusion: </strong> We cultivate a welcoming
                  environment that celebrates diversity. By eliminating barriers
                  to accessing our services, we affirm the right of every
                  individual to belong. Our commitment to inclusion extends
                  beyond rhetoric, creating spaces where everyone feels valued
                  and embraced.
                </div>
                <br />

                <div>
                  <strong style={{color:'#17496D'}}>5- People-focused: </strong> Central to our approach
                  is a people-focused mindset. We prioritize building meaningful
                  relationships with individuals and families. Respecting their
                  values and beliefs, we empower them to make informed decisions
                  about their care, ensuring a collaborative and personalized
                  approach that honors their unique journey.
                </div>
              </div>
            </Text>

            
          </Stack>
          <Img
            w={{ base: '100%', md: '50%' }}
            h={{ base: '40vh', md: '50vh', lg: '60vh' }}
            src={children}
            borderRadius={'5px'}
          />
        </Stack>
      </Stack>
    </Layout>
  );
};

export default AboutUs;
