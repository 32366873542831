import React, { useState } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Button,
  Center,
  Stack,
  Text,
  color,
  Spacer,
  Textarea,
  chakra,
  Heading,
  Checkbox,
} from '@chakra-ui/react';

import './ContactUs.css';
import connectUSImg from '../../assets/images/connect-us.jpeg';
import Layout from '../layout/Layout';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  return (
    <Layout>
      {/*Connect us Form*/}
      <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={5}>
        <Heading
          color={'#17496D'}
          fontSize={{ base: '1rem', md: '1.8rem', lg: '2rem' }}
          fontWeight={'bold'}
          className="heading"
          mb={2}
        >
          Contact us
        </Heading>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          justify="flex-start"
          align="start"
        >
          {/* Image */}
          <Box w={{ base: '100%', lg: '45%' }} py={2}>
            <img
              className="contactus-image"
              src={connectUSImg}
              alt="Your Image"
              style={{
                width: '100%',
                height: '60vh',
                borderRadius: '3px',
                // objectFit: 'contain',
              }}
            />
          </Box>

          {/* Form */}
          <Box
            w={{ base: '100%', lg: '50%' }}
            mt={{ base: '40px', lg: '0' }}
            ml={{ base: '0', lg: '40px' }}
          >
            <form>
              <Stack mb={4} spacing={2}>
                <Heading
                  color={'#17496D'}
                  fontSize={{ base: '1rem', md: '1.5rem', lg: '1.5rem' }}
                  fontWeight={'bold'}
                  className="heading"
                  mb={2}
                >
                  We would love to hear from you!
                </Heading>
                <Text pr={3} fontSize={'md'} color="#343434">
                  If you are interested in accessing our services, you can get
                  started by completing the contact form below. We will get back
                  to you to discuss how we can address the needs of your child,
                  provide clarity on our programs, location, fees, and payment
                  options.
                </Text>
              </Stack>
              <div>
                Please provide the following details in the message box for
                expedited assistance:
              </div>
              <div
                style={{
                  whiteSpace: 'pre-line',
                  lineHeight: '27px',
                  marginTop: '15px',
                }}
              >
                {`1- Age of your child
                2- City of residence
3- Which program of services are you interested in?
4- How many days per week can your child attend our services?
5- Has your child previously participated in any services?
6- Is your child currently enrolled in daycare/preschool/school? If so, please specify the schedule (days of the week and times of day).
`}
              </div>

              <Stack mt={6} spacing={4}>
                <Stack spacing={6} direction={{ base: 'column', lg: 'row' }}>
                  <FormControl>
                    <FormLabel
                      color={'#17496D'}
                      fontSize={{ base: '1rem', md: '1rem', lg: '1rem' }}
                      fontWeight={'600'}
                    >
                      First Name<chakra.span color={'#DC2D2D'}>*</chakra.span>
                    </FormLabel>
                    <Input
                      _hover={{}}
                      fontSize={'md'}
                      placeholder="John"
                      border={'1px solid #17496D'}
                      placeholderTextColor="rgba(23, 73, 109, 0.30)"
                      type="text"
                      name="fName"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      color={'#17496D'}
                      fontSize={{ base: '1rem', md: '1rem', lg: '1rem' }}
                      fontWeight={'600'}
                    >
                      Last Name<chakra.span color={'#DC2D2D'}>*</chakra.span>
                    </FormLabel>
                    <Input
                      _hover={{}}
                      fontSize={'md'}
                      placeholder="John"
                      border={'1px solid #17496D'}
                      placeholderTextColor="rgba(23, 73, 109, 0.30)"
                      type="text"
                      name="lName"
                    />
                  </FormControl>
                </Stack>
                <Stack spacing={6} direction={{ base: 'column', lg: 'row' }}>
                  <FormControl>
                    <FormLabel
                      color={'#17496D'}
                      fontSize={{ base: '1rem', md: '1rem', lg: '1rem' }}
                      fontWeight={'600'}
                    >
                      Subject
                    </FormLabel>
                    <Input
                      _hover={{}}
                      fontSize={'md'}
                      placeholder="Subject"
                      border={'1px solid #17496D'}
                      placeholderTextColor="rgba(23, 73, 109, 0.30)"
                      type="text"
                      name="companyName"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      color={'#17496D'}
                      fontSize={{ base: '1rem', md: '1rem', lg: '1rem' }}
                      fontWeight={'600'}
                    >
                      Phone No.
                    </FormLabel>
                    <Input
                      _hover={{}}
                      fontSize={'md'}
                      placeholder="829 212 4342"
                      border={'1px solid #17496D'}
                      placeholderTextColor="rgba(23, 73, 109, 0.30)"
                      type="number"
                      name="phone"
                    />
                  </FormControl>
                </Stack>
                <FormControl>
                  <FormLabel
                    color={'#17496D'}
                    fontSize={{ base: '1rem', md: '1rem', lg: '1rem' }}
                    fontWeight={'600'}
                  >
                    Email
                  </FormLabel>
                  <Input
                    _hover={{}}
                    fontSize={'md'}
                    placeholder="Johndoe@sns.comJohn Doe"
                    border={'1px solid #17496D'}
                    placeholderTextColor="rgba(23, 73, 109, 0.30)"
                    type="email"
                    name="email"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    color={'#17496D'}
                    fontSize={{ base: '1rem', md: '1rem', lg: '1rem' }}
                    fontWeight={'600'}
                  >
                    Message
                  </FormLabel>
                  <Textarea
                    rows={8}
                    _hover={{}}
                    fontSize={'md'}
                    placeholder="Lorem ipsum dolor sit amet consectetur. Varius rhoncus egestas vel vitae interdum lacinia consequat. Pellentesque amet ut auctor nec consequat. Vel laoreet volutpat convallis cursus a molestie. Etiam nisi ornare eget ornare."
                    border={'1px solid #17496D'}
                    placeholderTextColor="rgba(23, 73, 109, 0.30)"
                    type="text"
                    name="message"
                  />
                </FormControl>
                {/* <Checkbox color="#343434" size="md">
                  I agree to receive other communications from SnSHRhub in
                  accordance with your privacy notice.
                </Checkbox> */}
                <Button
                  mt={4}
                  alignSelf={'end'}
                  px={10}
                  w="fit-content"
                  size="md"
                  color="white"
                  _hover={{}}
                  bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
                  boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
                  borderRadius={'5px'}
                  fontWeight="400"
                >
                  Submit
                </Button>
              </Stack>
            </form>
          </Box>
        </Flex>
      </Stack>
    </Layout>
  );
};

export default ContactUs;
