import React, { useContext, useEffect, useState } from 'react';
import {
  Heading,
  Stack,
  Text,
  Button,
  Divider,
  Img,
  chakra,
  HStack,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import Layout from '../layout/Layout';
import i from '../../assets/images/i.svg';
import pptx from '../../assets/images/pptx.svg';
import xls from '../../assets/images/xls.svg';
import docx from '../../assets/images/docx.svg';
import pic1 from '../../assets/images/pdf.png';
import pic2 from '../../assets/images/docx.png';
import pic3 from '../../assets/images/pptx.png';
import pic4 from '../../assets/images/xls.png';
import pic5 from '../../assets/images/txt.png';
import pic6 from '../../assets/images/xer.png';
import pic7 from '../../assets/images/jpg.png';
import { useNavigate } from 'react-router-dom';
import BlogsDataContext from '../../context/BlogsContext';
import { Bars } from 'react-loader-spinner';

const HrPolicyTemplate = () => {
  const nav = useNavigate();

  const [loading, setloading] = useState();
  const [policyTemplates, setpolicyTemplates] = useState([]);
  const { getBlogsByType } = useContext(BlogsDataContext);

  useEffect(() => {
    setloading(true);
    const getBlogs = async () => {
      const res = await getBlogsByType();
      const toolkitsArray =
        res?.data?.hrResources?.filter(
          blog => blog.category === 'HR Policy Templates'
        ) || [];

      setpolicyTemplates(toolkitsArray);
      setloading(false);
    };
    getBlogs();
  }, []);

  function truncateUrl(url, maxLength) {
    if (url?.length <= maxLength) {
      return url;
    } else {
      const truncatedUrl = url?.substring(0, maxLength - 3) + '...';
      return truncatedUrl;
    }
  }
  return (
    <Layout>
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <Bars
              height="60"
              width="60"
              color="#17496D"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      )}
      <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={5}>
        <Heading
          classname="heading"
          color={'#17496D'}
          fontSize={{ base: '1.3rem', md: '1.8rem', lg: '2rem' }}
          fontWeight={'700'}
          mb={6}
        >
          ABA Therapy Resources
        </Heading>
        <Text
          whiteSpace="pre-line"
          color={'#343434'}
          fontSize={{ base: '0.75rem', md: '0.85rem', lg: '0.95rem' }}
          fontWeight={'500'}
          marginBottom={'0'}
        >
          {
            'Remember to critically evaluate information and seek guidance from qualified professionals when implementing ABA techniques with your child. Below are resources that should give you a solid foundation in understanding ABA therapy and its applications.'
          }
        </Text>
        <div style={{ fontWeight: '600', marginTop: '10PX' }}>Books</div>
        <ul style={{ marginLeft: '10px' }}>
          <li style={{ marginBottom: '10px' }}>
            "Applied Behavior Analysis" by John O. Cooper, Timothy E. Heron, and
            William L. Heward. This is often considered the definitive textbook
            on ABA and provides comprehensive coverage of the principles and
            techniques.
          </li>
          <li style={{ marginBottom: '10px' }}>
            "Teaching Individuals with Developmental Delays: Basic Intervention
            Techniques" by O. Ivar Lovaas: Lovaas is a pioneer in the field of
            ABA therapy for autism, and this book offers insights into his early
            work.{' '}
          </li>
          <li style={{ marginBottom: '10px' }}>
            "Applied Behavior Analysis" by John O. Cooper, Timothy E. Heron, and
            William L. Heward. This is often considered the definitive textbook
            on ABA and provides comprehensive coverage of the principles and
            techniques.
          </li>
        </ul>
        <div style={{ fontWeight: '600', marginTop: '10PX' }}>Websites</div>
        <ul style={{ marginLeft: '10px' }}>
          <li style={{ marginBottom: '10px' }}>
            Behavior Analyst Certification Board (BACB) website:{' '}
            <span
              onClick={() => window.open(`https://www.bacb.com/`, '_blank')}
              style={{ color: '#418DB8', cursor: 'pointer' }}
            >
              <a>https://www.bacb.com/</a>
            </span>
            <br />
            The BACB is the organization that certifies behavior analysts. Their
            website offers information about ABA certification requirements,
            ethical guidelines, and resources for professionals.
          </li>
          <li style={{ marginBottom: '10px' }}>
            Association for Behavior Analysis International (ABAI) website:{' '}
            <span
              onClick={() =>
                window.open(
                  `https://www.abainternational.org/welcome.aspx
`,
                  '_blank'
                )
              }
              style={{ color: '#418DB8', cursor: 'pointer' }}
            >
              <a>https://www.abainternational.org/welcome.aspx</a>
            </span>
            <br />
            ABAI is a professional organization that promotes the study and
            application of behavior analysis. Their website provides access to
            research articles, conference information, and other resources.
          </li>
          <li style={{ marginBottom: '10px' }}>
            Autism Speaks website:{' '}
            <span
              onClick={() => window.open(`https://www.autismspeaks.ca/`, '_blank')}
              style={{ color: '#418DB8', cursor: 'pointer' }}
            >
              <a>https://www.autismspeaks.ca/</a>
            </span>
            <br />
            This organization offers resources and information about autism,
            including articles and videos about ABA therapy.
          </li>
        </ul>
        <div style={{ fontWeight: '600', marginTop: '10PX' }}>
          Journals/Articles:
        </div>
        <ul style={{ marginLeft: '10px' }}>
          <li style={{ marginBottom: '10px' }}>
            Journal of Applied Behavior Analysis (JABA): This is the flagship
            journal in the field of ABA, publishing research articles and case
            studies related to behavior analysis and therapy.
          </li>
          <li style={{ marginBottom: '10px' }}>
            The Behavior Analyst: This journal covers a broad range of topics in
            behavior analysis, including research, theory, and practice.
          </li>
          <li style={{ marginBottom: '10px' }}>
            Journal of Autism and Developmental Disorders: While not specific to
            ABA, this journal often publishes research studies on ABA therapy
            for individuals with autism.
          </li>
        </ul>
      </Stack>
    </Layout>
  );
};

export default HrPolicyTemplate;
