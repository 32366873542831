import React, { useState } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Button,
  Center,
  Stack,
  Text,
  color,
  Spacer,
  Textarea,
  chakra,
  Heading,
  Checkbox,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';

import '../contactus/ContactUs.css';
import connectUSImg from '../../assets/images/subscribe.jpg';
import Layout from '../layout/Layout';

const DigitalSubscription = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [value, setValue] = React.useState('1');

  return (
    <Layout>
      {/*Connect us Form*/}
      <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={5}>
        <Heading
          color={'#17496D'}
          fontSize={{ base: '1rem', md: '1.8rem', lg: '2rem' }}
          fontWeight={'bold'}
          className="heading"
          mb={2}
        >
          Subscribe
        </Heading>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          justify="flex-start"
          align="start"
        >
          {/* Image */}
          <Box w={{ base: '100%', lg: '45%' }} py={2}>
            <img
              className="contactus-image"
              src={connectUSImg}
              alt="Your Image"
              style={{
                width: '100%',
                height: '60vh',
                borderRadius: '3px',
                // objectFit: 'contain',
              }}
            />
          </Box>

          {/* Form */}
          <Box
            w={{ base: '100%', lg: '50%' }}
            mt={{ base: '40px', lg: '0' }}
            ml={{ base: '0', lg: '40px' }}
          >
            <form>
              <Stack mb={4} spacing={2}>
                <Heading
                  color={'#17496D'}
                  fontSize={{ base: '1rem', md: '1.5rem', lg: '1.5rem' }}
                  fontWeight={'bold'}
                  className="heading"
                  mb={2}
                >
                  Subscribe to Stay Connected!
                </Heading>
                <Text pr={3} fontSize={'md'} color="#343434">
                  Join our newsletter today and be the first to receive our
                  latest updates on our services, news, and exclusive offers
                  delivered straight to your inbox. Subscribe anytime to stay
                  informed. Don't miss out, sign up now!
                </Text>
              </Stack>

              <Stack mt={6} spacing={4}>
                <Stack spacing={6} direction={{ base: 'column', lg: 'row' }}>
                  <FormControl>
                    <FormLabel
                      color={'#17496D'}
                      fontSize={{ base: '1rem', md: '1rem', lg: '1rem' }}
                      fontWeight={'600'}
                    >
                      First Name<chakra.span color={'#DC2D2D'}>*</chakra.span>
                    </FormLabel>
                    <Input
                      _hover={{}}
                      fontSize={'md'}
                      placeholder="John"
                      border={'1px solid #17496D'}
                      placeholderTextColor="rgba(23, 73, 109, 0.30)"
                      type="text"
                      name="fName"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      color={'#17496D'}
                      fontSize={{ base: '1rem', md: '1rem', lg: '1rem' }}
                      fontWeight={'600'}
                    >
                      Last Name<chakra.span color={'#DC2D2D'}>*</chakra.span>
                    </FormLabel>
                    <Input
                      _hover={{}}
                      fontSize={'md'}
                      placeholder="John"
                      border={'1px solid #17496D'}
                      placeholderTextColor="rgba(23, 73, 109, 0.30)"
                      type="text"
                      name="lName"
                    />
                  </FormControl>
                </Stack>
                <FormControl>
                  <FormLabel
                    color={'#17496D'}
                    fontSize={{ base: '1rem', md: '1rem', lg: '1rem' }}
                    fontWeight={'600'}
                  >
                    Email
                  </FormLabel>
                  <Input
                    _hover={{}}
                    fontSize={'md'}
                    placeholder="Johndoe@sns.comJohn Doe"
                    border={'1px solid #17496D'}
                    placeholderTextColor="rgba(23, 73, 109, 0.30)"
                    type="email"
                    name="email"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    color={'#17496D'}
                    fontSize={{ base: '1rem', md: '1rem', lg: '1rem' }}
                    fontWeight={'600'}
                  >
                    Mailing List
                  </FormLabel>
                  <RadioGroup onChange={setValue} value={value}>
                    <Stack direction="column">
                      <Radio
                        value="I'm a parent"
                      >
                        I'm a parent
                      </Radio>
                      <Radio value="I'm a professional">I'm a professional</Radio>
                      <Radio value="I'm a client">I'm a client</Radio>
                      <Radio value="I'm a supporter">I'm a supporter</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
                {/* <Checkbox color="#343434" size="md">
                  I agree to receive other communications from SnSHRhub in
                  accordance with your privacy notice.
                </Checkbox> */}
                <Button
                  mt={4}
                  alignSelf={'end'}
                  px={10}
                  w="fit-content"
                  size="md"
                  color="white"
                  _hover={{}}
                  bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
                  boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
                  borderRadius={'5px'}
                  fontWeight="400"
                >
                  Submit
                </Button>
              </Stack>
            </form>
          </Box>
        </Flex>
      </Stack>
    </Layout>
  );
};

export default DigitalSubscription;
