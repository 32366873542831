import React from 'react';
import Layout from '../../layout/Layout';
import { Button, Heading, Image, Img, Stack, Text } from '@chakra-ui/react';
import st from '../../../assets/images/pot.jpg';
import contactus from '../../../assets/images/contactus.svg';
import { useNavigate } from 'react-router-dom';

const SpecificChangeManagment = () => {
  const nav = useNavigate();
  return (
    <Layout>
      <Stack width={'100%'} px={{ base: 3, md: 6, lg: 12, xl: 20 }} py={6}>
        <Heading
          color={'#17496D'}
          fontSize={{ base: '1rem', md: '1.8rem', lg: '2rem' }}
          fontWeight={'700'}
          className="heading"
          mb={8}
        >
          Our Programs
        </Heading>
        <Stack
          gap={{ base: 12, md: 10, lg: 12, xl: '7vw' }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Img
            w={{ base: '100%', md: '50%' }}
            h={{ base: '40vh', md: '50vh', lg: '60vh' }}
            src={st}
            borderRadius={'5px'}
          />
          <Stack gap={0}>
            {/* <Text fontWeight={'600'} fontSize={'15px'} color="#FF6217">
              HR Digital Transformation Services
            </Text> */}
            <Heading
              color={'#17496D'}
              fontSize={{ base: '1rem', md: '1.8rem', lg: '1.8rem' }}
              fontWeight={'700'}
              className="heading"
              mt={2}
              mb={4}
            >
              Occupational Therapy
            </Heading>
            <div>
              Occupational therapy (OT) is a healthcare profession that focuses
              on helping children develop, regain, or maintain the skills needed
              for daily living and meaningful activities, or occupations.
              Occupational therapists work with learners of all ages who may be
              facing physical, developmental, emotional, or cognitive challenges
              that affect their ability to engage in activities that are
              important to them.
            </div>
            <br />
            <div>Key components of occupational therapy include:</div>
            <br />
            <div>
              <strong style={{ color: '#17496D' }}>
                1- Assessment and Evaluation:{' '}
              </strong>{' '}
              Occupational therapists conduct assessments to understand a
              learner's strengths, challenges, and goals. This may involve
              evaluating physical, cognitive, emotional, and sensory abilities
              related to daily activities. Learner's strengths, challenges, and
              goals. This may involve evaluating physical, cognitive, emotional,
              and sensory abilities related to daily activities.
            </div>
            <br />
            <div>
              <strong style={{ color: '#17496D' }}>
                2- Individualized Treatment Planning:{' '}
              </strong>
              Based on the assessment, occupational therapists develop
              individualized treatment plans to address specific goals and
              challenges. These plans are tailored to the unique needs and
              preferences of each learner.
            </div>
            <br />
            <div>
              <strong style={{ color: '#17496D' }}>
                3- Daily Living Skills (ADLs):{' '}
              </strong>
              Occupational therapy often focuses on activities of daily living
              (ADLs), which include self-care tasks such as dressing, grooming,
              bathing, eating, and toileting. Therapists work to enhance
              independence in these essential activities.
            </div>
            <br />
            <div>
              <strong style={{ color: '#17496D' }}>
                4- Fine and Gross Motor Skills:{' '}
              </strong>
              Occupational therapists address motor skills, both fine and gross
              motor skills. Fine motor skills involve the coordination of small
              muscles for tasks like writing or buttoning clothes, while gross
              motor skills involve larger muscle groups for activities like
              walking or running.
            </div>
            <br />
            <div>
              <strong style={{ color: '#17496D' }}>
                5- Sensory Integration:{' '}
              </strong>
              Occupational therapy may include interventions to address sensory
              processing issues. This involves helping learners better
              understand and respond to sensory information, which can impact
              behavior, attention, and overall well-being.
            </div>
            <br />
            <div>
              <strong style={{ color: '#17496D' }}>
                6- Cognitive Skills:{' '}
              </strong>
              Occupational therapy can address cognitive skills such as memory,
              attention, problem-solving, and executive functioning. Therapists
              work to improve cognitive abilities that are essential for daily
              functioning.
            </div>
            <br />
            <div>
              <strong style={{ color: '#17496D' }}>
                7- Workplace or School Modifications:{' '}
              </strong>
              For learners facing challenges in the workplace or academic
              settings, occupational therapists may recommend modifications to
              the environment, tools, or tasks to facilitate participation and
              success.
            </div>
            <br />
            <div>
              <strong style={{ color: '#17496D' }}>
                8- Adaptive Equipment and Assistive Technology:{' '}
              </strong>
              Occupational therapists may introduce and train individuals in the
              use of adaptive equipment or assistive technology to enhance
              independence in daily activities.
            </div>
            <br />
            <div>
              <strong style={{ color: '#17496D' }}>
                9- Mental Health and Emotional Well-Being:{' '}
              </strong>
              OT interventions can support learners in managing mental health
              challenges, improving emotional regulation, and developing coping
              strategies for daily life.
            </div>

            {/* <Text
              color={'#343434'}
              fontSize={{ base: '0.75rem', md: '0.85rem', lg: '0.95rem' }}
              fontWeight={'500'}
              marginBottom={'0'}
              whiteSpace="pre-line"
              lineHeight={{ base: '23px', lg: '27px' }}
            >
              {text}
            </Text> */}
            <Button
              mt={3}
              onClick={() => nav('/contact-us')}
              size={{ base: 'sm', md: 'lg' }}
              leftIcon={<Image src={contactus} />}
              color="white"
              _hover={{}}
              bg="linear-gradient(15deg, #17496D 26.3%, #418DB8 86.4%)"
              boxShadow={'0px 2.25px 9px 0px rgba(74, 58, 255, 0.18)'}
              borderRadius={'5px'}
              fontWeight={'400'}
              w="fit-content"
            >
              Work with us
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default SpecificChangeManagment;
